import {Pair} from "@/sdk/entities";
import {RootState} from "@/store";
import {
  Bar,
  DatafeedConfiguration, ErrorCallback, HistoryCallback,
  IBasicDataFeed, LibrarySymbolInfo,
  OnReadyCallback, PeriodParams,
  ResolutionString, ResolveCallback,
  SearchSymbolsCallback, SubscribeBarsCallback, SymbolResolveExtension
} from "public/charting_library";
import {SearchSymbolResultItem} from "public/charting_library/datafeed-api";
import {computed} from "vue";
import {useStore} from "vuex";

const lastBarsCache = new Map();
// Make requests to CryptoCompare API
export async function makeApiRequest(path: string) {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_ENDPOINT}/${path}`);
    return response.json();
  } catch (error) {
    throw new Error(`CryptoCompare request error: ${error.status}`);
  }
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange:string, fromSymbol:string|undefined, toSymbol:string|undefined) {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
    ticker: `${fromSymbol?.toUpperCase()}${toSymbol?.toUpperCase()}`
  };
}

export function parseFullSymbol(fullSymbol:string) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }

  return {
    exchange: match[1],
    fromSymbol: match[2],
    toSymbol: match[3],
  };
}

const configurationData: DatafeedConfiguration = {
  supported_resolutions: [
    '1' as ResolutionString,
    '5' as ResolutionString,
    '15' as ResolutionString,
    '30' as ResolutionString,
    '1h' as ResolutionString,
    '4h' as ResolutionString,
    '1D' as ResolutionString,
    //'1W' as ResolutionString,
    //'1M' as ResolutionString
  ],
  exchanges: [{
    value: 'Moofi',
    name: 'Moofi',
    desc: 'Moo finance',
  }
  ],
  symbols_types: [{
    name: 'crypto',

    // `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
    value: 'crypto',
  },
    // ...
  ],
};


let interval : any


export const useDatafeed = () => {
  const store = useStore<RootState>()
  const allPairs = computed(() => store.getters['dex/pair/ALL_PAIR'])

  async function getAllSymbols() {
    //const store = useStore<RootState>()
    //const allPairs = store.getters['dex/pair/ALL_PAIR']
    //const data = await makeApiRequest('data/v3/all/exchanges');
    let allSymbols:Array<SearchSymbolResultItem> = [];

    const exchange = 'Moofi'

    allSymbols = [ ...allPairs.value.map((pair:Pair) => {
      const symbol = generateSymbol(exchange, pair.token0.symbol, pair.token1.symbol);
      return {
        symbol: symbol.short,
        full_name: symbol.full,
        description: symbol.short,
        exchange,
        ticker: symbol.ticker,
        type: 'crypto',
      } as SearchSymbolResultItem;
    })]

    allSymbols = [ ...allSymbols, ...allPairs.value.map((pair:Pair) => {
      const symbol = generateSymbol(exchange, pair.token1.symbol, pair.token0.symbol);
      return {
        symbol: symbol.short,
        full_name: symbol.full,
        description: symbol.short,
        exchange,
        ticker: symbol.ticker,
        type: 'crypto',
      } as SearchSymbolResultItem;
    })]

    //console.log(allSymbols)

    return allSymbols;
  }


  const Datafeed = (): IBasicDataFeed => {
    return  {
      onReady(callback: OnReadyCallback): void {
     //   console.log('[onReady]: Method call');
        setTimeout(() => callback(configurationData));
      },
      async searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): Promise<void> {
     //   console.log('[searchSymbols]: Method call');
        const symbols = await getAllSymbols();
        const newSymbols = symbols.filter(symbol => {
          const isExchangeValid = exchange === '' || symbol.exchange === exchange;
          const isFullSymbolContainsInput = symbol.full_name
            .toLowerCase()
            .indexOf(userInput.toLowerCase()) !== -1;
          return isExchangeValid && isFullSymbolContainsInput;
        });
        onResult(newSymbols);
      },
      async resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback, extension: SymbolResolveExtension | undefined): Promise<void> {
        //console.log('[resolveSymbol]: Method call', symbolName);
        const symbols = await getAllSymbols();
        const symbolItem = symbols.find(({
                                           full_name,
                                         }) => full_name === symbolName);
        if (!symbolItem) {
          console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
          onError('cannot resolve symbol');
          return;
        }
        const pricescale: any = {
          'Moofi:KLAY/IZA': 1000,
          'Moofi:IZA/KLAY': 100000000,
          'Moofi:KUSDT/IZA': 1000,
          'Moofi:IZA/KUSDT': 100000000,
          'Moofi:KDAI/IZA': 1000,
          'Moofi:IZA/KDAI': 100000000,
        }
        const symbolInfo = {
          ticker: symbolItem.full_name,
          name: symbolItem.symbol,
          description: symbolItem.description,
          type: symbolItem.type,
          session: '24x7',
          timezone: 'Etc/UTC',
          exchange: symbolItem.exchange,
          minmov: 1,
          minmove2: 0,
          pricescale: pricescale[symbolName] ? pricescale[symbolName] : 10000,
          has_intraday: true,
          has_no_volume: false,
          has_weekly_and_monthly: false,
          supported_resolutions: configurationData.supported_resolutions,
          volume_precision: 10,
          data_status: 'streaming',
        } as LibrarySymbolInfo;

        //console.log('[resolveSymbol]: Symbol resolved', symbolName);
        onResolve(symbolInfo);
      },
      async getBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParams, onResult: HistoryCallback, onError: ErrorCallback): Promise<void> {
        const { from, to, firstDataRequest } = periodParams;

        const _syncData = async () => {
          try {
            const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
            if (!parsedSymbol)
              throw new Error('Fail parseFullSymbol')
            const urlParameters: { [k: string]: string | number | boolean } = {
              e: parsedSymbol.exchange,
              fs: parsedSymbol.fromSymbol,
              ts: parsedSymbol.toSymbol,
              ft: from,
              tt: to,
              pr: resolution,
            };
            const query = Object.keys(urlParameters)
              .filter(name => urlParameters[name])
              .map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
              .join('&');

            const data = await makeApiRequest(`api/trades?${query}`);
            let bars: any[] = [];
            data.forEach((bar: any) => {
              bars = [...bars, {
                time: bar.time * 1000,
                low: bar.low,
                high: bar.high,
                open: bar.open,
                close: bar.close,
                volume: bar.volume,
              }];
            });

            if (bars.length === 0) {
              onResult([], {noData: true});
              return;
            }
            onResult(bars, {
              noData: false,
            });

          } catch (error) {
            onError(error)
          }
        }

        await _syncData()

        /*
        *
        if(interval) {
          clearInterval(interval)
        }
        interval = setInterval(async () => {
          console.log('timer')
          await _syncData()
        }, 60000)
        * */

      },
      subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void): void {
        console.log('[subscribeBars]: Method call with subscribeUID:', listenerGuid);
        onResetCacheNeededCallback()
      },
      unsubscribeBars(listenerGuid: string): void {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', listenerGuid);
      }

    }
  }

  return {
    Datafeed,
  }


}

