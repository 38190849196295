import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { ref: "refCanvas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_app_footer = _resolveComponent("app-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", _hoisted_2, null, 512),
    _createVNode(_component_app_header),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_app_footer)
  ]))
}