import {BigNumber} from "ethers";

export const MaxUint256 = BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')

// exports for internal consumption
export const ZERO = BigNumber.from(0)
export const ONE = BigNumber.from(1)
export const TWO = BigNumber.from(2)
export const THREE = BigNumber.from(3)
export const FIVE = BigNumber.from(5)
export const TEN = BigNumber.from(10)
export const _100 = BigNumber.from(100)
export const _997 = BigNumber.from(997)
export const _1000 = BigNumber.from(1000)

export const MINIMUM_LIQUIDITY = BigNumber.from(1000)
