

const useFractalCanvas = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext("2d");
  //ctx.canvas.width = window.innerWidth

  if(ctx) {
    ctx.canvas.width  = window.innerWidth;
    ctx.canvas.height = window.innerHeight;
  }

  const getRandomInRange = (min:any, max:any) => {
    return Math.random() * (max - min) + min;
  };

  const TOTAL_POINTS = window.innerWidth > window.innerHeight ? (window.innerWidth*window.innerHeight/20000) : (window.innerWidth*window.innerHeight/10000);
  const CONNECT_DISTANCE = window.innerWidth > window.innerHeight ? (window.innerWidth/10) : (window.innerHeight/8);

  let points: any[] = [];

  const drawPoint = (point : any) => {
    if(ctx) {
      ctx.beginPath();
      ctx.arc(point.x, point.y, point.c, 0, 2 * Math.PI);
      ctx.fillStyle = `rgba(255, 255, 255, 1)`;
      ctx.fill();
    }
  };

  const movePoint = (point : any) => {
    point.x += point.s * Math.cos(point.a);
    point.y += point.s * Math.sin(point.a);
  };

  const distance = (point: any, other: any) => {
    return Math.sqrt((other.x - point.x) ** 2 + (other.y - point.y) ** 2);
  };

  const drawLine = (point: any, other:any, d:any) => {
    if(ctx) {
      ctx.beginPath();
      ctx.moveTo(point.x, point.y);
      ctx.lineTo(other.x, other.y);
      ctx.strokeStyle = `rgba(255, 255, 255, ${Math.abs(d / CONNECT_DISTANCE - 1)})`;
      ctx.stroke();
    }
  };

  const loopStart = () => {
    window.requestAnimationFrame(loopStart);

    for (let i = points.length; i < TOTAL_POINTS; i++) {
      points.push({
        x: getRandomInRange(0, canvas.width),
        y: getRandomInRange(0, canvas.height),
        a: getRandomInRange(0, 360),
        s: getRandomInRange(.5,1),
        c: getRandomInRange(1,5)
      });
    }

    if(ctx)
      ctx.clearRect(0, 0, canvas.width, canvas.height);

    points.forEach(point => {
      movePoint(point);
    });

    points = points.filter(point => {
      return (
        point.x >= 0 &&
        point.x < canvas.width &&
        point.y >= 0 &&
        point.y < canvas.height
      );
    });

    points.forEach(point => {
      drawPoint(point);
    });

    points.forEach(point => {
      points.forEach(other => {
        if (point === other) {
          return;
        }

        const d = distance(point, other);
        if (d < CONNECT_DISTANCE) {
          drawLine(point, other, d);
        }
      });
    });
  };
  return {
    loopStart,
  }
}

export default useFractalCanvas
