import useWeb3Provider, {connectWallet, useWallet} from "@/composables/useWeb3Provider";
import {getAllTokens} from "@/sdk/api/dex";
import {Token} from "@/sdk/entities";
import {createToast} from "mosha-vue-toastify";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store";

export class Web3State {
  public selectedAddress = ''
  public currentBlockNumber = 0


}

const mutations:MutationTree<Web3State> = {
  SET_ADDRESSES(state, payload:string) {
    state.selectedAddress = payload
  },
  SET_BLOCK_NUMBER(state, payload) {
    state.currentBlockNumber = payload
  }
}

const actions:ActionTree<Web3State, RootState> = {
  async connectedWallet({ commit }) {
    const { address } = useWeb3Provider()
    commit('SET_ADDRESSES', address)

  },
  async connectWallet({ commit }) {
      const {account} = await connectWallet()
      commit('SET_ADDRESSES', account)
  },
  async getBlockNumber({ commit }) {
    const { provider } = useWeb3Provider()

    const blockNumber = await provider.getBlockNumber()
    commit('SET_BLOCK_NUMBER', blockNumber)
  }

}

const getters: GetterTree<Web3State, RootState> = {

  ADDRESS(state) {
    return state.selectedAddress
  },
  BLOCK_NUMBER(state) {
    return state.currentBlockNumber
  }
}


const moduleWeb3: Module<Web3State, RootState> = {
  namespaced: true,
  state: new Web3State(),
  actions,
  mutations,
  getters
}

export default moduleWeb3
