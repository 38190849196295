
import {
  useApprovalTokenToLPDistributor,
  useApprovalTokenToRouter, useLPDistributor,
  useTokenBalance,
  useTotalSupply
} from "@/composables/useTokenBalance";
import {WETH9} from "@/sdk/constants";
import {CurrencyAmount, Pair, Token} from "@/sdk/entities";
import {RootState} from "@/store";
import {ethers} from "ethers";
import {createToast} from "mosha-vue-toastify";
import {computed, defineComponent, ref, watch} from "vue";
import {useStore} from "vuex";

const LiquidityStake = defineComponent({
  name: "LiquidityStake",
  props: {
    address: {required: true},
  },
  emits: ['updateStaked'],
  setup(props, { emit }) {
    const store = useStore<RootState>()
    const pair = computed<Pair>(() => store.getters["dex/pair/GET_PAIR"](props.address))
    const token0 = computed<Token>(() => pair.value.token0)
    const token1 = computed<Token>(() => pair.value.token1)

    const depositMode = ref<boolean>(true)
    const changeDepositMode = (deposit:boolean) => depositMode.value = deposit

    const { formatted: lpTokenFormattedBalance, raw: lpTokenRawBalance, fetchBalance: fetchLpTokenBalance } = useTokenBalance(pair.value.liquidityToken)
    const { raw: lpTokenTotalSupply, fetchSupply: fetchLpTokenSupply } = useTotalSupply(pair.value.liquidityToken)

    const { raw: lpTokenApproved, approve: lpTokenApprove, fetchApproved: fetchLpTokenApproved } = useApprovalTokenToLPDistributor(pair.value.liquidityToken)

    const { rawPendingIza, formattedPendingIza, rawStakedLPBalance, formattedStakedLPBalance, stakeLiquidity: _stakeLiquidity, unstakeLiquidity: _unstakeLiquidity, fetchStakedLPBalance, fetchPendingIza, claimStartBlock, harvest } = useLPDistributor(pair.value)

    const claimable = computed(() => Number(rawPendingIza.value.quotient) && store.getters['web3/BLOCK_NUMBER'] > claimStartBlock.value)
    const claim = async () => {
      if(!claimable.value)
        return

      try {
        await harvest()
        await fetchPendingIza()
      } catch (error) {

        createToast('Transaction Failed', {
          type: 'danger',
          position: 'bottom-center'
        })
      }

    }

    const checkApprovalDepositLpToken = () => {
      return lpTokenApproved.value.lessThan(rawDepositAmountLP.value)
    }

    const depositAmountLP = ref('')
    const rawDepositAmountLP = computed(() => CurrencyAmount.fromRawAmount(pair.value.liquidityToken, ethers.utils.parseUnits(Number(depositAmountLP.value).toFixed(token0.value.decimals), pair.value.liquidityToken.decimals)))

    const withdrawAmountLP = ref('')
    const rawWithdrawAmountLP = computed(() => CurrencyAmount.fromRawAmount(pair.value.liquidityToken, ethers.utils.parseUnits(Number(withdrawAmountLP.value).toFixed(token0.value.decimals), pair.value.liquidityToken.decimals)))

    const lpDepositBtnDisabled = computed( () => {
      if(!depositAmountLP.value)
        return true;

      if(depositAmountLP.value && rawDepositAmountLP.value.greaterThan(lpTokenRawBalance.value))
        return true

      return false
    })
    const lpWithdrawBtnDisabled = computed( () => {
      if(!withdrawAmountLP.value)
        return true;

      if(withdrawAmountLP.value && rawWithdrawAmountLP.value.greaterThan(rawStakedLPBalance.value))
        return true;

      return false
    })

    const onKeyupDecimal = (e:KeyboardEvent) => {
      const charCode = e.which ? e.which : e.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        e.preventDefault()
        return false
      } else {
        return true
      }
    }
    const lpDepositBtnActionText = computed(() => {
      if(!depositAmountLP.value)
        return `예치하기`

      if(depositAmountLP.value && rawDepositAmountLP.value.greaterThan(lpTokenRawBalance.value))
        return `LP 잔고 부족`

      if(depositAmountLP.value && lpTokenApproved.value.lessThan(rawDepositAmountLP.value))
        return `LP 전송 승인`

      return '예치하기'
    })
    const lpWithdrawBtnActionText = computed(() => {
      if(!withdrawAmountLP.value)
        return `회수하기`

      if(withdrawAmountLP.value && rawWithdrawAmountLP.value.greaterThan(rawStakedLPBalance.value))
        return `회수가능 LP 초과`

      return '회수하기'
    })

    const checkApprovalLpToken = () => {
      return lpTokenApproved.value.lessThan(rawDepositAmountLP.value)
    }

    const lpDeposit = async () => {
      try {
        if (checkApprovalLpToken()) {
          await lpTokenApprove()
          await fetchLpTokenApproved()
          createToast('Success Approve', {
            type: 'success',
            position: 'bottom-center'
          })
          return
        }

        await _stakeLiquidity(rawDepositAmountLP.value.quotient)
        await fetchLpTokenBalance()
        await fetchLpTokenSupply()
        await fetchStakedLPBalance()

        depositAmountLP.value = ''
        createToast('Success LP Stake', {
          type: 'success',
          position: 'bottom-center'
        })

        emit('updateStaked')

      } catch (error) {

        createToast('Transaction Failed', {
          type: 'danger',
          position: 'bottom-center'
        })
      }
    }

    const lpWithdraw = async () => {
      try {

        await _unstakeLiquidity(rawWithdrawAmountLP.value.quotient)
        await fetchLpTokenBalance()
        await fetchLpTokenSupply()
        await fetchStakedLPBalance()

        withdrawAmountLP.value = ''

        createToast('Success LP Unstake', {
          type: 'success',
          position: 'bottom-center'
        })
        emit('updateStaked')

      } catch (error) {

        createToast('Transaction Failed', {
          type: 'danger',
          position: 'bottom-center'
        })
      }
    }

    const onClickDepositAmountMax = () => {
      if(!Number(lpTokenRawBalance.value.toSignificant()))
        return

      depositAmountLP.value = lpTokenFormattedBalance.value
    }

    const onClickWithdrawAmountMax = () => {
      if(!Number(rawStakedLPBalance.value.toSignificant()))
        return

      withdrawAmountLP.value = formattedStakedLPBalance.value
    }

    return {
      pair,
      token0,
      token1,
      lpTokenFormattedBalance,
      depositAmountLP,
      withdrawAmountLP,
      lpDepositBtnActionText,
      lpWithdrawBtnActionText,
      lpDepositBtnDisabled,
      lpWithdrawBtnDisabled,
      depositMode,
      changeDepositMode,
      lpDeposit,
      lpWithdraw,
      rawPendingIza,
      formattedPendingIza,
      formattedStakedLPBalance,
      onKeyupDecimal,
      claimable,
      claim,
      onClickDepositAmountMax,
      onClickWithdrawAmountMax
    }
  }
})

export default LiquidityStake
