import useWeb3Provider from "@/composables/useWeb3Provider";
import {getDeployment} from "@/sdk";
import {useUSDTPrice} from "@/sdk/api/dex";
import {GOVERNANCE, MaxUint256, POOL_IDS, WETH9} from "@/sdk/constants";
import {Currency, CurrencyAmount, Fraction, Pair, Percent, Token} from "@/sdk/entities";
import pair from "@/store/dex/pair";
import klaytn from "@/store/klaytn";
import {isAddress} from "@ethersproject/address";
import Caver from "caver-js";
import {BigNumber, BigNumberish, ethers} from "ethers";
import invariant from "tiny-invariant";
import {useStore} from "vuex";
import {RootState} from "@/store";
import {computed, Ref, ref, watch} from "vue";
import {
  BareERC20,
  BareERC20__factory,
  MooPair,
  MooPair__factory,
  MooRouter,
  MooRouter__factory,
  LPDistributor,
  LPDistributor__factory,
  LPDistributorV2__factory, LPDistributorV2, GenesisPass__factory, LPDistributorV202, LPDistributorV202__factory
} from '@/contract-sdk/types'

declare let window: any


export const useTokenBalance = (token: Token) => {
  const { provider } = useWeb3Provider()
  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])
  const tokenBalance = ref<CurrencyAmount<Token>>(CurrencyAmount.fromRawAmount(token, 0))
  const formattedBalance = computed(() => tokenBalance.value.toSignificant(6))

  const fetchBalance = async () => {
    async function getBalance() {
      if(!address.value)
        return CurrencyAmount.fromRawAmount(token, BigNumber.from(0))
      //console.log(tokenAddress, address.value)

      if(token.address === '0x0000000000000000000000000000000000000000' || token.address === WETH9.address)
        return CurrencyAmount.fromRawAmount(token, await provider.getBalance(address.value))

      const tokenContract =  await BareERC20__factory.connect(token.address, provider)

      return CurrencyAmount.fromRawAmount(token, await tokenContract.balanceOf(address.value))
    }
    const balance = await getBalance()
    tokenBalance.value = balance
  }

  watch(address, async () => {
    await fetchBalance()
    //console.log(formattedBalance.value)
  }, {
    immediate: true,
  })

  return {
    raw: tokenBalance,
    formatted: formattedBalance,
    fetchBalance
  }
}

export const useTokenBalanceRef = (token: Ref<Token>) => {
  const { provider } = useWeb3Provider()
  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])
  const tokenBalance = ref<CurrencyAmount<Token>>(CurrencyAmount.fromRawAmount(token.value, 0))
  const formattedBalance = computed(() => tokenBalance.value.toSignificant(6))

  const fetchBalance = async () => {
    async function getBalance() {
      if(!address.value)
        return CurrencyAmount.fromRawAmount(token.value, BigNumber.from(0))
      //console.log(tokenAddress, address.value)

      if(token.value.address === '0x0000000000000000000000000000000000000000' || token.value.address === WETH9.address)
        return CurrencyAmount.fromRawAmount(token.value, await provider.getBalance(address.value))

      const tokenContract =  await BareERC20__factory.connect(token.value.address, provider)

      return CurrencyAmount.fromRawAmount(token.value, await tokenContract.balanceOf(address.value))
    }
    const balance = await getBalance()
    tokenBalance.value = balance
  }

  watch([address,token], async () => {
    //console.log(token.value.symbol, ' changed')
    await fetchBalance()
    //console.log(formattedBalance.value)
  }, {
    immediate: true,
  })

  return {
    raw: tokenBalance,
    formatted: formattedBalance,
    fetchBalance
  }
}

export const getTokenBalance = async (token: Token, address: string) => {
  const { provider } = useWeb3Provider()
  const tokenContract =  await BareERC20__factory.connect(token.address, provider)

  if(token.address === '0x0000000000000000000000000000000000000000' || token.address === WETH9.address)
    return CurrencyAmount.fromRawAmount(token, await provider.getBalance(address))

  return CurrencyAmount.fromRawAmount(token, address ? await tokenContract.balanceOf(address) : BigNumber.from(0))
}

export const getPairReserves = async (pairAddress: string) => {
  const { provider } = useWeb3Provider()
  const pairContract =  await MooPair__factory.connect(pairAddress, provider)

  return await pairContract.getReserves()
}


export const useTokenBalanceByAddress = (token: Token, holder: string) => {
  const { provider } = useWeb3Provider()
  const store = useStore<RootState>()
  const tokenBalance = ref<CurrencyAmount<Token>>(CurrencyAmount.fromRawAmount(token, 0))
  const formattedBalance = computed(() => tokenBalance.value.toSignificant(6))

  const fetchBalance = async () => {
    async function getBalance() {
      if(!isAddress(holder))
        return CurrencyAmount.fromRawAmount(token, BigNumber.from(0))
      //console.log(tokenAddress, address.value)

      //if(token.address === '0x0000000000000000000000000000000000000000')
      //  return CurrencyAmount.fromRawAmount(WETH9, await provider.getBalance(holder))

      const tokenContract =  await BareERC20__factory.connect(token.address, provider)

      return CurrencyAmount.fromRawAmount(token, await tokenContract.balanceOf(holder))
    }
    const balance = await getBalance()
    tokenBalance.value = balance
  }

  ;(async () => {
    await fetchBalance()
  })()

  return {
    raw: tokenBalance,
    formatted: formattedBalance,
    fetchBalance
  }
}

export const useTotalSupply = (token: Token) => {
  const { provider } = useWeb3Provider()
  const store = useStore<RootState>()
  //const address = computed(() => store.getters['web3/ADDRESS'])
  const totalSupply = ref<CurrencyAmount<Token>>(CurrencyAmount.fromRawAmount(token, 0))
  const formattedTotalSupply = computed(() => totalSupply.value.toSignificant(6))

  const fetchSupply = async () => {
    async function getSupply() {

      //if(tokenAddress === '0x0000000000000000000000000000000000000000' || tokenAddress === WETH9.address)
      //  return await provider.getBalance(address.value)

      const tokenContract =  await BareERC20__factory.connect(token.address, provider)

      return CurrencyAmount.fromRawAmount(token, await tokenContract.totalSupply())
    }
    const supply = await getSupply()
    totalSupply.value = supply
  }

  ;(async () => {
    await fetchSupply()
  })()
  /*watch(address, async () => {
    await fetchBalance()
    //console.log(formattedBalance.value)
  }, {
    immediate: true,
  })*/

  return {
    raw: totalSupply,
    formatted: formattedTotalSupply,
    fetchSupply
  }
}

export const useKLast = (token: Token) => {
  const { provider } = useWeb3Provider()
  const store = useStore<RootState>()
  //const address = computed(() => store.getters['web3/ADDRESS'])
  const kLast = ref<BigNumber>(BigNumber.from(0))

  const fetchKLast = async () => {
    async function getKLast() {
      const pairContract =  await MooPair__factory.connect(token.address, provider)
      return await pairContract.kLast()
    }
    const _kLast = await getKLast()
    kLast.value = _kLast
  }

  ;(async () => {
    await fetchKLast()
  })()

  return {
    raw: kLast,
    fetchKLast
  }



}

export const useApprovalTokenToRouter = (token: Token) => {
  const spender = getDeployment('MooRouter').address

  return useApprovalToken(token, spender)
}

export const useApprovalTokenToRouterRef = (token: Ref<Token>) => {
  const spender = getDeployment('MooRouter').address

  return useApprovalTokenRef(token, spender)
}

export const useApprovalTokenToLPDistributor = (token: Token) => {
  const spender = getDeployment('LPDistributorV2').address

  return useApprovalToken(token, spender)
}

export const useApprovalToken = (token: Token, spender: string) => {
  const { provider } = useWeb3Provider()
  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])

  const approved = ref<CurrencyAmount<Token>>(CurrencyAmount.fromRawAmount(token, 0))

  const fetchApproved = async () => {
    async function getApproved() {
      if(!address.value)
        return CurrencyAmount.fromRawAmount(token,BigNumber.from(0))

      const tokenContract =  BareERC20__factory.connect(token.address, provider)

      return CurrencyAmount.fromRawAmount(token,await tokenContract.allowance(address.value, spender))
    }

    const approval = await getApproved()
    approved.value = approval
  }

  const approve = async (amount:BigNumberish = MaxUint256) => {
    invariant(address.value, 'CONNECT_WALLET')

    const tokenContract = (new ethers.Contract(token.address,BareERC20__factory.createInterface())) as BareERC20

    const data = tokenContract.interface.encodeFunctionData("approve", [spender, amount])

    const caver = new Caver(window.klaytn)

    const gasLimit = await caver.rpc.klay.estimateGas({
        from: address.value,
        to: tokenContract.address,
        data: data,
      })
    const res = await caver.rpc.klay.sendTransaction({
      type:'SMART_CONTRACT_EXECUTION',
      from: address.value,
      to: tokenContract.address,
      data: data,
      gas: (Number(gasLimit)*1.2).toFixed(0),
    })

  }

  watch(address, async () => {
    await fetchApproved()
  }, {
    immediate: true,
  })

  return {
    raw: approved,
    fetchApproved,
    approve,
  }
}

export const useApprovalTokenRef = (token: Ref<Token>, spender: string) => {
  const { provider } = useWeb3Provider()
  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])

  const approved = ref<CurrencyAmount<Token>>(CurrencyAmount.fromRawAmount(token.value, 0))

  const fetchApproved = async () => {
    async function getApproved() {
      if(!address.value)
        return CurrencyAmount.fromRawAmount(token.value,BigNumber.from(0))

      const tokenContract =  BareERC20__factory.connect(token.value.address, provider)

      return CurrencyAmount.fromRawAmount(token.value,await tokenContract.allowance(address.value, spender))
    }

    const approval = await getApproved()
    approved.value = approval
  }

  const approve = async (amount:BigNumberish = MaxUint256) => {
    invariant(address.value, 'CONNECT_WALLET')

    const tokenContract = (new ethers.Contract(token.value.address,BareERC20__factory.createInterface())) as BareERC20

    const data = tokenContract.interface.encodeFunctionData("approve", [spender, amount])
    const caver = new Caver(window.klaytn)

    const gasLimit = await caver.rpc.klay.estimateGas({
      from: address.value,
      to: tokenContract.address,
      data: data,
    })
    const res = await caver.rpc.klay.sendTransaction({
      type:'SMART_CONTRACT_EXECUTION',
      from: address.value,
      to: tokenContract.address,
      data: data,
      gas: (Number(gasLimit)*1.2).toFixed(0),
    })

  }

  watch([address, token], async () => {
    await fetchApproved()
  }, {
    immediate: true,
  })

  return {
    raw: approved,
    fetchApproved,
    approve,
  }
}
export const useSwapRouter = () => {
  const { provider } = useWeb3Provider()
  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])

  const addLiquidity = async (pair: Pair, token0:BigNumber, token1:BigNumber) => {
    invariant(address.value, 'CONNECT_WALLET')

    const routerContract = (new ethers.Contract(getDeployment('MooRouter').address, MooRouter__factory.createInterface())) as MooRouter

    if(!pair.token0.equals(WETH9) && !pair.token1.equals(WETH9)) {
      const data = routerContract.interface.encodeFunctionData("addLiquidity",
        [pair.token0.address, pair.token1.address,token0, token1, token0.mul(98).div(100), token1.mul(98).div(100), address.value, MaxUint256])

      const caver = new Caver(window.klaytn)

      const gasLimit = await caver.rpc.klay.estimateGas({
        from: address.value,
        to: routerContract.address,
        data: data,
      })

      const res = await caver.rpc.klay.sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address.value,
        to: routerContract.address,
        data: data,
        gas: (Number(gasLimit) * 1.2).toFixed(0),
      })
    } else {

      if(pair.token0.equals(WETH9)) {
        const data = routerContract.interface.encodeFunctionData("addLiquidityETH",
          [pair.token1.address, token1, token1.mul(98).div(100), token0.mul(98).div(100), address.value, MaxUint256])

        const caver = new Caver(window.klaytn)

        const gasLimit = await caver.rpc.klay.estimateGas({
          from: address.value,
          to: routerContract.address,
          data: data,
          value: token0.toString()
        })

        const res = await caver.rpc.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: address.value,
          to: routerContract.address,
          data: data,
          gas: (Number(gasLimit) * 1.2).toFixed(0),
          value: token0.toString()
        })
      } else {
        const data = routerContract.interface.encodeFunctionData("addLiquidityETH",
          [pair.token0.address, token0, token0.mul(98).div(100), token1.mul(98).div(100), address.value, MaxUint256])

        const caver = new Caver(window.klaytn)

        const gasLimit = await caver.rpc.klay.estimateGas({
          from: address.value,
          to: routerContract.address,
          data: data,
          value: token1.toString()
        })

        const res = await caver.rpc.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: address.value,
          to: routerContract.address,
          data: data,
          gas: (Number(gasLimit) * 1.2).toFixed(0),
          value: token1.toString()
        })
      }
    }
  }
  const removeLiquidity = async (pair: Pair, liquidity: BigNumber, token0:BigNumber, token1:BigNumber) => {
    invariant(address.value, 'CONNECT_WALLET')

    const routerContract = (new ethers.Contract(getDeployment('MooRouter').address, MooRouter__factory.createInterface())) as MooRouter
    if(!pair.token0.equals(WETH9) && !pair.token1.equals(WETH9)) {

      const data = routerContract.interface.encodeFunctionData("removeLiquidity",
        [pair.token0.address, pair.token1.address, liquidity, token0.mul(98).div(100), token1.mul(98).div(100), address.value, MaxUint256])

      const caver = new Caver(window.klaytn)

      const gasLimit = await caver.rpc.klay.estimateGas({
        from: address.value,
        to: routerContract.address,
        data: data,
      })

      const res = await caver.rpc.klay.sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address.value,
        to: routerContract.address,
        data: data,
        gas: (Number(gasLimit) * 1.2).toFixed(0),
      })
    } else {
      if(pair.token0.equals(WETH9)) {

        const data = routerContract.interface.encodeFunctionData("removeLiquidityETH",
          [pair.token1.address, liquidity, token1.mul(98).div(100), token0.mul(98).div(100), address.value, MaxUint256])

        const caver = new Caver(window.klaytn)

        const gasLimit = await caver.rpc.klay.estimateGas({
          from: address.value,
          to: routerContract.address,
          data: data,
        })

        const res = await caver.rpc.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: address.value,
          to: routerContract.address,
          data: data,
          gas: (Number(gasLimit) * 1.2).toFixed(0),
        })
      } else {

        const data = routerContract.interface.encodeFunctionData("removeLiquidityETH",
          [pair.token0.address, liquidity, token0.mul(98).div(100), token1.mul(98).div(100), address.value, MaxUint256])

        const caver = new Caver(window.klaytn)

        const gasLimit = await caver.rpc.klay.estimateGas({
          from: address.value,
          to: routerContract.address,
          data: data,
        })

        const res = await caver.rpc.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: address.value,
          to: routerContract.address,
          data: data,
          gas: (Number(gasLimit) * 1.2).toFixed(0),
        })
      }
    }
  }

  return {
    addLiquidity,
    removeLiquidity
  }
}
export const useOldLPDistributor = (pair: Pair) => {
  const { provider } = useWeb3Provider()
  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])
  const lpDistributorAddress = getDeployment('LPDistributor').address
  const poolId = POOL_IDS[pair.liquidityToken.address]

  const pendingIza = ref<CurrencyAmount<Token>>(CurrencyAmount.fromRawAmount(pair.liquidityToken,BigNumber.from(0)))
  const formattedPendingIza = computed(() => pendingIza.value.toSignificant(6))

  const fetchPendingIza = async () => {

    async function getPendingIza() {
      if(!address.value)
        return CurrencyAmount.fromRawAmount(pair.liquidityToken,BigNumber.from(0))

      const lpDistributor = LPDistributor__factory.connect(lpDistributorAddress, provider)

      return CurrencyAmount.fromRawAmount(pair.liquidityToken,await lpDistributor.pendingIza(poolId, address.value))
    }

    const _pendingIza = await getPendingIza()
    pendingIza.value = _pendingIza
  }

  const stakedLPBalance = ref<CurrencyAmount<Token>>(CurrencyAmount.fromRawAmount(pair.liquidityToken,BigNumber.from(0)))
  const formattedStakedLPBalance = computed(() => stakedLPBalance.value.toSignificant(6))

  const fetchStakedLPBalance = async () => {
    async function getStakeLPBalance() {
      if(!address.value)
        return CurrencyAmount.fromRawAmount(pair.liquidityToken,BigNumber.from(0))
      const lpDistributor = LPDistributor__factory.connect(lpDistributorAddress, provider)
      const userInfo = await lpDistributor.userInfo(poolId, address.value)
      return CurrencyAmount.fromRawAmount(pair.liquidityToken,userInfo.amount)
    }
    const _stakedLPBalance = await getStakeLPBalance()
    stakedLPBalance.value = _stakedLPBalance
  }

  const withdrawAndHarvest = async () => {
    invariant(address.value, 'CONNECT_WALLET')
    const lpDistributorContract = (new ethers.Contract(lpDistributorAddress, LPDistributor__factory.createInterface())) as LPDistributor

    const data = lpDistributorContract.interface.encodeFunctionData("withdrawAndHarvest",
      [poolId, stakedLPBalance.value.quotient, address.value])

    const caver = new Caver(window.klaytn)

    const gasLimit = await caver.rpc.klay.estimateGas({
      from: address.value,
      to: lpDistributorContract.address,
      data: data,
    })

    const res = await caver.rpc.klay.sendTransaction({
      type:'SMART_CONTRACT_EXECUTION',
      from: address.value,
      to: lpDistributorContract.address,
      data: data,
      gas: (Number(gasLimit)*1.2).toFixed(0),
    })
  }


  watch(address, async () => {
    await fetchPendingIza()
    await fetchStakedLPBalance()

  }, {
    immediate: true,
  })

  return {
    formattedOldPendingIza: formattedPendingIza,
    rawOldPendingIza: pendingIza,
    rawOldStakedLPBalance: stakedLPBalance,
    formattedOldStakedLPBalance: formattedStakedLPBalance,
    fetchOldPendingIza: fetchPendingIza,
    fetchOldStakedLPBalance: fetchStakedLPBalance,
    oldWithdrawAndHarvest: withdrawAndHarvest,
  }
}
export const useLPDistributor = (pair: Pair) => {
  const { provider } = useWeb3Provider()
  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])
  const lpDistributorAddress = getDeployment('LPDistributorV2').address
  const poolId = POOL_IDS[pair.liquidityToken.address]

  const pendingIza = ref<CurrencyAmount<Token>>(CurrencyAmount.fromRawAmount(pair.liquidityToken,BigNumber.from(0)))
  const formattedPendingIza = computed(() => pendingIza.value.toSignificant(6))

  const claimStartBlock = ref<number>(999999999)


  const fetchClaimStartBlock = async () => {
    async function getClaimStartBlock() {
      const lpDistributor = LPDistributorV2__factory.connect(lpDistributorAddress, provider)
      return Number(await lpDistributor.claimStartBlock())
    }

    claimStartBlock.value = await getClaimStartBlock()
  }

  const stakeLiquidity = async (liquidity: BigNumber) => {
    invariant(address.value, 'CONNECT_WALLET')
    const lpDistributorContract = (new ethers.Contract(lpDistributorAddress, LPDistributorV2__factory.createInterface())) as LPDistributorV2

    const data = lpDistributorContract.interface.encodeFunctionData("deposit",
      [poolId, liquidity, address.value])

    const caver = new Caver(window.klaytn)

    const gasLimit = await caver.rpc.klay.estimateGas({
      from: address.value,
      to: lpDistributorContract.address,
      data: data,
    })

    const res = await caver.rpc.klay.sendTransaction({
      type:'SMART_CONTRACT_EXECUTION',
      from: address.value,
      to: lpDistributorContract.address,
      data: data,
      gas: (Number(gasLimit)*1.2).toFixed(0),
    })
  }

  const unstakeLiquidity = async (liquidity: BigNumber) => {
    invariant(address.value, 'CONNECT_WALLET')
    const lpDistributorContract = (new ethers.Contract(lpDistributorAddress, LPDistributorV2__factory.createInterface())) as LPDistributorV2

    const data = lpDistributorContract.interface.encodeFunctionData("withdraw",
      [poolId, liquidity, address.value])

    const caver = new Caver(window.klaytn)

    const gasLimit = await caver.rpc.klay.estimateGas({
      from: address.value,
      to: lpDistributorContract.address,
      data: data,
    })

    const res = await caver.rpc.klay.sendTransaction({
      type:'SMART_CONTRACT_EXECUTION',
      from: address.value,
      to: lpDistributorContract.address,
      data: data,
      gas: (Number(gasLimit)*1.2).toFixed(0),
    })
  }

  const fetchPendingIza = async () => {

    async function getPendingIza() {
      if(!address.value)
        return CurrencyAmount.fromRawAmount(pair.liquidityToken,BigNumber.from(0))

      const lpDistributor = LPDistributorV2__factory.connect(lpDistributorAddress, provider)

      return CurrencyAmount.fromRawAmount(pair.liquidityToken,await lpDistributor.pendingIza(poolId, address.value))
    }

    const _pendingIza = await getPendingIza()
    pendingIza.value = _pendingIza

  }

  const stakedLPBalance = ref<CurrencyAmount<Token>>(CurrencyAmount.fromRawAmount(pair.liquidityToken,BigNumber.from(0)))
  const formattedStakedLPBalance = computed(() => stakedLPBalance.value.toSignificant(6))

  const fetchStakedLPBalance = async () => {
    async function getStakeLPBalance() {
      if(!address.value)
        return CurrencyAmount.fromRawAmount(pair.liquidityToken,BigNumber.from(0))
      const lpDistributor = LPDistributorV2__factory.connect(lpDistributorAddress, provider)
      const userInfo = await lpDistributor.userInfo(poolId, address.value)
      return CurrencyAmount.fromRawAmount(pair.liquidityToken,userInfo.amount)
    }
    const _stakedLPBalance = await getStakeLPBalance()
    stakedLPBalance.value = _stakedLPBalance
  }

  const harvest = async () => {
    invariant(address.value, 'CONNECT_WALLET')
    const lpDistributorContract = (new ethers.Contract(lpDistributorAddress, LPDistributorV2__factory.createInterface())) as LPDistributorV2

    const data = lpDistributorContract.interface.encodeFunctionData("harvest",
      [poolId, address.value])

    const caver = new Caver(window.klaytn)

    const gasLimit = await caver.rpc.klay.estimateGas({
      from: address.value,
      to: lpDistributorContract.address,
      data: data,
    })

    const res = await caver.rpc.klay.sendTransaction({
      type:'SMART_CONTRACT_EXECUTION',
      from: address.value,
      to: lpDistributorContract.address,
      data: data,
      gas: (Number(gasLimit)*1.2).toFixed(0),
    })
  }

  const poolAPR = ref()

  const fetchPoolApr = async () => {
    async function getPoolApr() {
      const lpDistributor = LPDistributorV202__factory.connect(lpDistributorAddress, provider)
      const token0USDPrice = useUSDTPrice(pair.token0)
      //console.log('token0 ', pair.token0.symbol, token0USDPrice?.quotient.toNumber())
      const token1USDPrice = useUSDTPrice(pair.token1)
     // console.log('token1 ', pair.token1.symbol, token1USDPrice?.quotient.toNumber())
      const governanceTokenPrice = useUSDTPrice(GOVERNANCE)
      //console.log('tokenGv',GOVERNANCE.symbol, governanceTokenPrice?.quotient.toNumber())

      if(!token0USDPrice || !token1USDPrice || !governanceTokenPrice)
        return '0.00'

      const totalValue = parseFloat(token0USDPrice.quote(pair.reserve0).toSignificant())+parseFloat(token1USDPrice.quote(pair.reserve1).toSignificant())

      const lpBalance = useTokenBalanceByAddress(pair.liquidityToken, lpDistributor.address)
      const poolInfo = await lpDistributor.poolInfo(poolId)
      const allocPoint = poolInfo.allocPoint
      const totalAllocPoint = await lpDistributor.totalAllocPoint()
      const lpSupply = useTotalSupply(pair.liquidityToken)

      await lpSupply.fetchSupply()
      await lpBalance.fetchBalance()
      const lpPrice = totalValue/parseFloat(lpSupply.raw.value.toFixed())

      //console.log(store.getters['web3/BLOCK_NUMBER'], Number(BigNumber.from(store.getters['web3/BLOCK_NUMBER']).add(365*24*60*60)))
      //let totalRewardPerYear = await lpDistributor.izaPerBlocks(BigNumber.from(store.getters['web3/BLOCK_NUMBER']), BigNumber.from(store.getters['web3/BLOCK_NUMBER']).add(365*24*60*60))
      const from = Number(store.getters['web3/BLOCK_NUMBER']) >= 92172081
        ? BigNumber.from(store.getters['web3/BLOCK_NUMBER'])
        : BigNumber.from(92172081)

      const totalRewardPerYear = await lpDistributor.izaPerBlocks(from, from.add(24*60*60))

      //let totalRewardPerYear = ethers.utils.parseEther('2951769600')

      //if(!Number(totalRewardPerYear))
      //  totalRewardPerYear = ethers.utils.parseEther('2951769600')

      const poolRewardPerYear = totalRewardPerYear.mul(360).mul(allocPoint).div(totalAllocPoint)

      const pricePoolRewardPerYear = parseFloat(governanceTokenPrice.quote(CurrencyAmount.fromRawAmount(GOVERNANCE,poolRewardPerYear)).toSignificant())
      const _lpBalance = parseFloat(lpBalance.raw.value.toSignificant())
//console.log('aaaa')
      try {
        return ((pricePoolRewardPerYear/(lpPrice*_lpBalance))*100).toFixed(2)
      } catch(err) {
        console.log(err)
        return '0'
      }

    }
    poolAPR.value = await getPoolApr()
  }

  watch(address, async () => {
    await fetchPendingIza()
    await fetchStakedLPBalance()
    await fetchClaimStartBlock()
  }, {
    immediate: true,
  })

  setInterval(async() => {
    await fetchPendingIza()
  }, 10000)

  return {
    formattedPendingIza,
    rawPendingIza: pendingIza,
    rawStakedLPBalance: stakedLPBalance,
    formattedStakedLPBalance,
    fetchPendingIza,
    stakeLiquidity,
    unstakeLiquidity,
    fetchStakedLPBalance,
    claimStartBlock,
    harvest,
    poolAPR,
    fetchPoolApr,
  }

}

export const useGenesisPass = () => {
  const { provider } = useWeb3Provider()
  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])
  const tokenBalance = ref<BigNumber>(BigNumber.from(0))
  const isProdMode = computed(()=>process.env.NODE_ENV==='production')

  const fetchBalance = async () => {
    async function getBalance() {
      if(isProdMode.value)
        return BigNumber.from(0)

      if(!address.value)
        return BigNumber.from(0)

      const tokenContract = await GenesisPass__factory.connect(getDeployment('GenesisPass').address, provider)

      return await tokenContract.balanceOf(address.value)
    }
    const balance = await getBalance()
    tokenBalance.value = balance
  }

  watch(address, async () => {
    await fetchBalance()
  }, {
    immediate: true,
  })

  return {
    isHolder: computed(() => Number(tokenBalance.value)>0)
  }

}
