import {Contract, ethers} from "ethers";
//import { MooPair } from '@contract-types'
import { MooFactory, MooFactory__factory } from '@/contract-sdk/types'

const deployments = JSON.parse(process.env.VUE_APP_DEPLOYMENTS!)

declare let window: any;
//console.log(JSON.parse(process.env.VUE_APP_DEPLOYMENTS!))
export const getDeployment = (contractName: string) => {

  if(!deployments['contracts'][contractName])
    throw new Error(`Can not found contract ${contractName}`)

  return deployments.contracts[contractName]
}

export const getContractAt = async () => {
  const { klaytn } = window

  const provider = new ethers.providers.JsonRpcProvider(process.env.VUE_APP_RPC_URL)

  const mooFactory = await MooFactory__factory.connect(getDeployment('MooFactory').address, provider)
  console.log(await mooFactory.allPairsLength())
  //
}

