import {RootState} from "@/store";
import modulePair, {PairState} from "@/store/dex/pair";
import moduleStake, {StakeState} from "@/store/dex/stake";
import moduleToken, {TokenState} from "@/store/dex/token";
import {Module} from "vuex";

export interface IDexState {
  //
  token: TokenState
  pair: PairState
  stake: StakeState
}
export class DexState implements IDexState {
  token = new TokenState()
  pair = new PairState()
  stake = new StakeState()
}

const moduleDex: Module<DexState, RootState> = {
  namespaced: true,
  modules: {
    token: moduleToken,
    pair: modulePair,
    stake: moduleStake,
  }
}

export default moduleDex
