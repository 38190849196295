import {useTokenBalanceByAddress, useTotalSupply} from "@/composables/useTokenBalance";
import useWeb3Provider from "@/composables/useWeb3Provider";
import {ERC20__factory, StakeDistributor__factory, StakedToken__factory} from "@/contract-sdk/types";
import {getDeployment} from "@/sdk";
import {GOVERNANCE} from "@/sdk/constants";
import {CurrencyAmount, Percent, Token} from "@/sdk/entities";
import {RootState} from "@/store";
import {MultipleToStakePeriod} from "@/store/dex/stake";
import Caver from "caver-js";
import {BigNumber, BigNumberish, ethers} from "ethers";
import invariant from "tiny-invariant";
import {computed, Ref, ref, watch} from "vue";
import {useStore} from "vuex";

declare let window: any
const sIzaAddress = getDeployment('StakedToken').address

export const useStakeTokenBalance = () => {
  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])
  const tokenBalance = ref<BigNumber>(BigNumber.from(0))
  const formattedBalance = computed(() => ethers.utils.formatEther(tokenBalance.value))
  const { provider } = useWeb3Provider()

  const fetchBalance = async () => {

    async function getBalance() {
      if(!address.value)
        return BigNumber.from(0)

      const tokenContract = await StakedToken__factory.connect(sIzaAddress, provider)

      return await tokenContract.balanceOf(address.value)
    }
    const balance = await getBalance()
    tokenBalance.value = balance
  }

  watch(address, async () => {
    await fetchBalance()
  }, {
    immediate: true,
  })

  return {
    raw: tokenBalance,
    formatted: formattedBalance,
    fetchBalance,
  }
}

//export const useApprovalStakeTokenBalance

export const useStake = () => {

  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])
  const { provider } = useWeb3Provider()

  const totalStakedIZABalance = ref<BigNumber>(BigNumber.from(0))
  const formattedTotalStakedIZABalance = computed(() => ethers.utils.formatEther(totalStakedIZABalance.value))
  const fetchTotalStakedIZA = async () => {

    async function getTotalStakedIZA() {
      const izaTokenContract = await ERC20__factory.connect(GOVERNANCE.address, provider)

      return await izaTokenContract.balanceOf(sIzaAddress)
    }

    totalStakedIZABalance.value = await getTotalStakedIZA()
  }

  const stakeReward = ref<BigNumber>(BigNumber.from(0))
  const formattedStakeReward = computed(() => ethers.utils.formatEther(stakeReward.value))
  const fetchStakeReward = async () => {

    async function getStakeReward() {
      const stakeDistributorContract = await StakeDistributor__factory.connect(getDeployment('StakeDistributor').address, provider)
      if(!address.value)
        return BigNumber.from(0)

      return await stakeDistributorContract.pendingIza(address.value)
    }

    stakeReward.value = await getStakeReward()
  }

  const accStakeReward = ref<BigNumber>(BigNumber.from(0))
  const formattedAccStakeReward = computed(() => ethers.utils.formatEther(accStakeReward.value))
  const fetchAccStakeReward = async () => {

    async function getAccStakeReward() {
      const stakeDistributorContract = await StakeDistributor__factory.connect(getDeployment('StakeDistributor').address, provider)
      if(!address.value)
        return BigNumber.from(0)

      return await stakeDistributorContract.rewardDebt(address.value)
    }

    accStakeReward.value = await getAccStakeReward()
  }

  const claimableUnstakeAmount = ref<BigNumber>(BigNumber.from(0))
  const formattedClaimableUnstakeAmount = computed(() => ethers.utils.formatEther(claimableUnstakeAmount.value))
  const claimedUnstakeAmount = ref<BigNumber>(BigNumber.from(0))
  const formattedClaimedUnstakeAmount = computed(() => ethers.utils.formatEther(claimedUnstakeAmount.value))
  const fetchClaimedInfo = async () => {

    async function getClaimedInfo() {
      const tokenContract = await StakedToken__factory.connect(sIzaAddress, provider)
      if(!address.value)
        return { _unstakibleAmount: BigNumber.from(0), _stakedAmount:BigNumber.from(0) }

      const claimedInfo = await tokenContract.claimedInfo(address.value)

      return {
        _unstakibleAmount: claimedInfo.unstakibleAmount,
        _stakedAmount: claimedInfo.stakedAmount
      }
    }

    const claimedInfo = await getClaimedInfo()
    claimableUnstakeAmount.value = claimedInfo._stakedAmount
    claimedUnstakeAmount.value = claimedInfo._unstakibleAmount
  }

  const stake = async (amount: BigNumber, multiple: number) => {
    invariant(address.value, 'Invalid Sender')
    invariant(Object.keys(store.getters['dex/stake/Periods']).includes(multiple.toString()))

    const tokenContract = await StakedToken__factory.connect(sIzaAddress, provider)
    const caver = new Caver(window.klaytn)

    const data = tokenContract.interface.encodeFunctionData('stake', [address.value, amount, multiple])

    const gasLimit = await caver.rpc.klay.estimateGas({
      from: address.value,
      to: tokenContract.address,
      data: data
    })

    const res = await caver.rpc.klay.sendTransaction({
      type: 'SMART_CONTRACT_EXECUTION',
      from: address.value,
      to: tokenContract.address,
      data: data,
      gas: (Number(gasLimit) * 1.2).toFixed(0)
    })

  }
  const unstake = async (amount: BigNumber, multiple: number) => {
    invariant(address.value, 'Invalid Sender')
    invariant(Object.keys(store.getters['dex/stake/Periods']).includes(multiple.toString()))

    const tokenContract = await StakedToken__factory.connect(sIzaAddress, provider)
    const caver = new Caver(window.klaytn)

    const data = tokenContract.interface.encodeFunctionData('unstake', [address.value, multiple, amount])

    const gasLimit = await caver.rpc.klay.estimateGas({
      from: address.value,
      to: tokenContract.address,
      data: data
    })

    const res = await caver.rpc.klay.sendTransaction({
      type: 'SMART_CONTRACT_EXECUTION',
      from: address.value,
      to: tokenContract.address,
      data: data,
      gas: (Number(gasLimit) * 1.2).toFixed(0)
    })
  }

  const harvest = async () => {
    invariant(address.value, 'Invalid Claimer')
    const stakeDistributorContract = await StakeDistributor__factory.connect(getDeployment('StakeDistributor').address, provider)
    const caver = new Caver(window.klaytn)
    const data = stakeDistributorContract.interface.encodeFunctionData('harvest', [address.value])

    const gasLimit = await caver.rpc.klay.estimateGas({
      from: address.value,
      to: stakeDistributorContract.address,
      data: data
    })

    const res = await caver.rpc.klay.sendTransaction({
      type: 'SMART_CONTRACT_EXECUTION',
      from: address.value,
      to: stakeDistributorContract.address,
      data: data,
      gas: (Number(gasLimit) * 1.2).toFixed(0)
    })
  }

  const claim = async () => {
    invariant(address.value, 'Invalid Claimer')
    invariant(claimedUnstakeAmount.value.gt(BigNumber.from(0)), 'not')
    const tokenContract = await StakedToken__factory.connect(sIzaAddress, provider)
    const caver = new Caver(window.klaytn)
    const data = tokenContract.interface.encodeFunctionData('claim', [address.value])

    const gasLimit = await caver.rpc.klay.estimateGas({
      from: address.value,
      to: tokenContract.address,
      data: data
    })

    const res = await caver.rpc.klay.sendTransaction({
      type: 'SMART_CONTRACT_EXECUTION',
      from: address.value,
      to: tokenContract.address,
      data: data,
      gas: (Number(gasLimit) * 1.2).toFixed(0)
    })
  }

  const holderAPR = ref<Percent>(new Percent(0,1))
  const fetchHolderApr = async () => {
    async function getHolderApr() {
      if(!address.value)
        return new Percent(0, 1)

      const stakeDistributorContract = await StakeDistributor__factory.connect(getDeployment('StakeDistributor').address, provider)
      const tokenContract = await StakedToken__factory.connect(sIzaAddress, provider)
      const periods = computed<MultipleToStakePeriod>(() => store.getters['dex/stake/Periods'])
      const sIzaBalance = await tokenContract.balanceOf(address.value)
      const sIzaSupply = await tokenContract.totalSupply()
      let totalStakedAmount = BigNumber.from(0)
      for(const [multiple, period] of Object.entries(periods.value)) {
        const {
          stakedAmount,
        } = await tokenContract.stakedInfo(address.value, multiple)
        totalStakedAmount = totalStakedAmount.add(stakedAmount)
      }
      if(!Number(sIzaBalance))
        return  new Percent(0, 1)

      const from = Number(store.getters['web3/BLOCK_NUMBER']) >= 92172081
        ? BigNumber.from(store.getters['web3/BLOCK_NUMBER'])
        : BigNumber.from(92172081)

      const totalRewardPerYear = await stakeDistributorContract.izaPerBlocks(from, from.add(24*60*60))


      const share = totalRewardPerYear.mul(360).mul(sIzaBalance).div(sIzaSupply)

      return new Percent(share,totalStakedAmount)
    }

    holderAPR.value = await getHolderApr()
  }

  const baseAPR = ref<Percent>(new Percent(0,1))
  const stakeSupply = ref<BigNumber>(BigNumber.from(0))
  const fetchBaseApr = async () => {
    async function getBaseApr() {
      const stakeDistributorContract = await StakeDistributor__factory.connect(getDeployment('StakeDistributor').address, provider)
      const tokenContract = await StakedToken__factory.connect(sIzaAddress, provider)
      const periods = computed<MultipleToStakePeriod>(() => store.getters['dex/stake/Periods'])

      const from = Number(store.getters['web3/BLOCK_NUMBER']) >= 92172081
        ? BigNumber.from(store.getters['web3/BLOCK_NUMBER'])
        : BigNumber.from(92172081)


      const totalRewardPerYear = await stakeDistributorContract.izaPerBlocks(from, from.add(24*60*60))
      //console.log(ethers.utils.formatEther(totalRewardPerYear))
      //let totalRewardPerYear = ethers.utils.parseEther('1967846400')
      //if(!Number(totalRewardPerYear))
      //  totalRewardPerYear = ethers.utils.parseEther('1967846400')
      const sIzaSupply = await tokenContract.totalSupply()
      let totalMultiple = BigNumber.from(0)
      for(const [multiple, period] of Object.entries(periods.value)) {

        totalMultiple = totalMultiple.add(BigNumber.from(multiple))
      }
      //console.log(`totalMultiple`, totalMultiple)
      const baseShare = totalRewardPerYear.mul(360)//.mul(100).div(totalMultiple)
      //console.log(`baseShare`, baseShare)

      return {
        apr: new Percent(baseShare, !Number(totalStakedIZABalance.value)?BigNumber.from(1).mul(BigNumber.from(10).pow(18)):totalStakedIZABalance.value),
        sIzaSupply: sIzaSupply,
      }
    }
    const _apr = await getBaseApr()
    baseAPR.value = _apr.apr
    stakeSupply.value = _apr.sIzaSupply
  }

  watch(address, async () => {
    await fetchStakeReward()
    await fetchHolderApr()
    await fetchClaimedInfo()
    await fetchAccStakeReward()
    await fetchBaseApr()
  }, {
    immediate: true,
  })

  setInterval(async() => {
    await fetchStakeReward()
    await fetchTotalStakedIZA()
  }, 10000)

  return {
    totalStakedIZABalance,
    formattedTotalStakedIZABalance,
    fetchTotalStakedIZA,

    holderAPR,
    holderAPRPct: computed(()=>holderAPR.value.toFixed(2)),
    fetchHolderApr,

    baseAPR,
    baseAPRPct: computed(()=>baseAPR.value.toFixed(2)),
    fetchBaseApr,

    stakeSupply,

    stakeReward,
    formattedStakeReward,
    accStakeReward,
    formattedAccStakeReward,
    fetchAccStakeReward,
    fetchStakeReward,
    fetchClaimedInfo,
    formattedClaimableUnstakeAmount,
    formattedClaimedUnstakeAmount,
    sIzaAddress,
    stake, unstake,harvest, claim
  }
}

export const useStakedInfo = (multiple: Ref<number|null>) => {

  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])
  const { provider } = useWeb3Provider()

  const unstakibleAmount = ref<BigNumber>(BigNumber.from(0))
  const formattedUnstakibleAmount = computed(() => ethers.utils.formatEther(unstakibleAmount.value))
  const stakedAmount = ref<BigNumber>(BigNumber.from(0))
  const formattedStakedAmount = computed(() => ethers.utils.formatEther(stakedAmount.value))
  const fetchStakedInfo = async () => {
    async function getStakedInfo() {
      const tokenContract = await StakedToken__factory.connect(sIzaAddress, provider)
      if(!address.value || !multiple.value || !Object.keys(store.getters['dex/stake/Periods']).includes(multiple.value.toString()))
        return { _unstakibleAmount: BigNumber.from(0), _stakedAmount:BigNumber.from(0) }

      const claimedInfo = await tokenContract.stakedInfo(address.value, multiple.value)
      return {
        _unstakibleAmount: claimedInfo.unstakibleAmount,
        _stakedAmount: claimedInfo.stakedAmount
      }
    }

    const claimedInfo = await getStakedInfo()
    unstakibleAmount.value = claimedInfo._unstakibleAmount
    stakedAmount.value = claimedInfo._stakedAmount
  }


  watch([address, multiple], async () => {
    await fetchStakedInfo()
  }, {
    immediate: true,
  })

  return {
    unstakibleAmount,
    stakedAmount,
    formattedUnstakibleAmount,
    formattedStakedAmount,
  }
}

export const useStakeHistory = () => {
  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])
  const { provider } = useWeb3Provider()
  const periods = computed<MultipleToStakePeriod>(() => store.getters['dex/stake/Periods'])

  const summary = ref<{[multiple:string]:{period:number, unstakibleAmount:string, stakedAmount:string}}>({})
  const histories = ref<Array<{ multiple:string,period:number,stakedAmount:string,endDay:number, startDayString:string,endDayString:string}>>([])

  const day = 24*60*60*1000
  function leftPad(value:number): string {
    return value >= 10 ? `${value}`: `0${value}`;
  }

  function dateFormatting(source:Date, withHours=false,  delimiter = '. '): string {
    const year = source.getFullYear();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());
    const hour = leftPad(source.getHours())

    return [year, month, day].join(delimiter)+ (withHours?`. ${hour}`:`.`);
  }
  const fetchStakeHistory = async () => {
    async function getStakeHistory() {
      const tokenContract = await StakedToken__factory.connect(sIzaAddress, provider)

      const summary: {[multiple:string]:{period:number, unstakibleAmount:string, stakedAmount:string}} = {}
      const histories: Array<{ multiple:string,period:number,stakedAmount:string,endDay:number, startDayString:string,endDayString:string}> = []
      if(!address.value)
        return {
          summary,
          histories,
        }

      for(const [multiple, period] of Object.entries(periods.value)) {
        const {
          unstakibleAmount,
          stakedAmount,
          stakedAmounts,
          endDays,
        } = await tokenContract.stakedInfo(address.value, multiple)
        summary[multiple] = {
          period: period as number,
          unstakibleAmount: ethers.utils.formatEther(unstakibleAmount),
          stakedAmount: ethers.utils.formatEther(stakedAmount),
        }

        for(const i in stakedAmounts) {
          histories.push({
            multiple,
            period,
            stakedAmount: ethers.utils.formatEther(stakedAmounts[i]),
            endDay: endDays[i],
            startDayString: dateFormatting(new Date((endDays[i]-period)*day)),
            endDayString: dateFormatting(new Date(endDays[i]*day), true)
          })
        }
      }

      return {
        summary,
        histories,
      }
    }

    const _fetchData = await getStakeHistory()
    histories.value = _fetchData.histories
    summary.value = _fetchData.summary
  }


/*  ;(async () => {
    await fetchStakeHistory()
  })()*/


  return {
    periods,
    summary,
    histories,
    fetchStakeHistory,
  }

}

export const useAPRByAddress = () => {
  const store = useStore<RootState>()
  const address = computed(() => store.getters['web3/ADDRESS'])
  const { provider } = useWeb3Provider()



  watch(address, async () => {
    //await fetchHolderApr()
  }, {
    immediate: true
  })

  return {
  }
}
