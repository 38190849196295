import {BigNumber} from "ethers";
import { ONE, TWO, ZERO } from '@/sdk/constants'

import invariant from 'tiny-invariant'

export const MAX_SAFE_INTEGER = BigNumber.from(Number.MAX_SAFE_INTEGER-1)

/**
 * Computes floor(sqrt(value))
 * @param value the value for which to compute the square root, rounded down
 */
export function sqrt(value: BigNumber): BigNumber {
  invariant(value.gte(ZERO), 'NEGATIVE')

  // rely on built in sqrt if possible
  if (value.lt(MAX_SAFE_INTEGER)) {
    return BigNumber.from(Math.floor(Math.sqrt(Number(value))))
  }

  let z: BigNumber
  let x: BigNumber
  z = value
  x = value.div(TWO).add(ONE)
  while (x.lt(z)) {
    z = x
    x = value.div(x).add(x).div(TWO)
  }
  return z
}

/**
 * Returns the smallest member of the array
 * @param values the values from which the smallest gets returned
 * @returns the smallest memmber of the array
 */
export function minimum(...values: BigNumber[]): BigNumber {
  let lowest = values[0]
  for (let i = 1; i < values.length; i++) {
    const value = values[i]
    if (value.lt(lowest)) {
      lowest = value
    }
  }
  return lowest
}

/**
 * Returns the biggest member of the array
 * @param values the values from which the biggest gets returned
 * @returns the biggest memmber of the array
 */
export function maximum(...values: BigNumber[]): BigNumber {
  let highest = values[0]
  for (let i = 1; i < values.length; i++) {
    const value = values[i]
    if (value.gt(highest)) {
      highest = value
    }
  }
  return highest
}

export function difference(a: BigNumber, b: BigNumber): BigNumber {
  if (a.gt(b)) {
    return a.sub(b)
  }
  return b.sub(a)
}
