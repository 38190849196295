
import StakeHistory from "@/components/stake/StakeHistory.vue";
import {useAPRByAddress, useStake, useStakedInfo, useStakeTokenBalance} from "@/composables/useStake";
import {useApprovalToken, useTokenBalance} from "@/composables/useTokenBalance";
import {GOVERNANCE, WETH9} from "@/sdk/constants";
import {Percent, Token} from "@/sdk/entities";
import {RootState} from "@/store";
import {BigNumber, ethers} from "ethers";
import {createToast} from "mosha-vue-toastify";
import invariant from "tiny-invariant";
import {computed, defineComponent, onMounted, ref} from "vue";
import { useStore } from "vuex";

const StakeProvide = defineComponent({
  name: "StakeProvide",
  components: {StakeHistory},
  setup(props) {
    const onLoad = ref(false)
    const store = useStore<RootState>()
    const periods = computed(() => store.getters['dex/stake/Periods'])
    const { formatted: izaFormattedBalance, raw: izaRawBalance, fetchBalance: fetchIzaBalance } = useTokenBalance(GOVERNANCE)
    const { formatted: sIzaFormattedBalance, raw: sIzaRawBalance, fetchBalance: fetchStakedIzaBalance } = useStakeTokenBalance()

    const amountStake = ref('')
    const rawAmountStake = computed<BigNumber>(() => ethers.utils.parseEther(amountStake.value))
    const amountUnstake = ref('')
    const rawAmountUnstake = computed<BigNumber>(() => ethers.utils.parseEther(amountUnstake.value))

    const selectedMultiple = ref<number|null>(null)
    const address = computed(() => store.getters['web3/ADDRESS'])

    const openStake = ref(false)
    const openUnstake = ref(false)

    const estimatedReceiveStakedToken = computed(() => parseFloat(amountStake.value?amountStake.value:'0')*(selectedMultiple.value?selectedMultiple.value/100:1))


    function leftPad(value:number): string {
      return value >= 10 ? `${value}`: `0${value}`;
    }

    function dateFormatting(source:Date, withHours=false,  delimiter = '. '): string {
      const year = source.getFullYear();
      const month = leftPad(source.getMonth() + 1);
      const day = leftPad(source.getDate());
      const hour = leftPad(source.getHours())

      return [year, month, day].join(delimiter)+ (withHours?`. ${hour}`:`.`);
    }

    const estimatedEndPeriod = computed(() => {
      if(!selectedMultiple.value)
        return '-'
      const endDay = Math.floor(Date.now()/(24*60*60*1000)) + (periods.value[selectedMultiple.value])

      return dateFormatting(new Date(endDay *24*60*60*1000), false)
    })

    const estimatedClaimableDate = computed(() => {
      return dateFormatting(new Date((Math.floor(Date.now()/(24*60*60*1000)) + 7)*24*60*60*1000), true)
    })

    const onClickOpenStake = () => {
      openStake.value = !openStake.value
      openUnstake.value = false
    }

    const onClickOpenUnstake = () => {
      openUnstake.value = !openUnstake.value
      openStake.value = false
    }

    const {
      totalStakedIZABalance,
      formattedTotalStakedIZABalance,
      stakeReward,
      formattedStakeReward,
      formattedAccStakeReward,
      formattedClaimableUnstakeAmount,
      formattedClaimedUnstakeAmount,
      sIzaAddress,
      stakeSupply,
      holderAPRPct,
      baseAPR,
      fetchHolderApr,
      fetchStakeReward,
      fetchClaimedInfo,
      fetchTotalStakedIZA,
      stake, unstake, harvest, claim
    } = useStake()

    const {
      unstakibleAmount,
      stakedAmount,
      formattedUnstakibleAmount,
      formattedStakedAmount,
    } = useStakedInfo(selectedMultiple)


    const { raw: izaApproved, approve: izaApprove, fetchApproved: fetchIzaApproved } = useApprovalToken(GOVERNANCE, sIzaAddress)
    const { raw: sIzaApproved, approve: sIzaApprove, fetchApproved: fetchSIzaApproved } = useApprovalToken(new Token(sIzaAddress,18,'sIza','Staked IZA'), sIzaAddress)

    const stakeBtnDisabled = computed(() => {
      if(!address.value)
        return true

      if(!amountStake.value)
        return true

      if(rawAmountStake.value.gt(izaRawBalance.value.quotient))
        return true

      if(!selectedMultiple.value)
        return true

        return false
    })

    const stakeActionText = computed(() => {
      if(!amountStake.value)
        return `STAKE`

      if(!selectedMultiple.value)
        return `스테이킹 배율 선택`

      if(rawAmountStake.value.gt(izaRawBalance.value.quotient))
        return `IZA 토큰 잔고 부족`

      if( izaApproved.value.quotient.lt(rawAmountStake.value))
        return `IZA 전송 승인`


      return `STAKE`
    })

    const onClickStake = async () => {
      invariant(selectedMultiple.value, '스테이킹 배율 선택')
      try {
        if( izaApproved.value.quotient.lt(rawAmountStake.value)) {
          await izaApprove()
          await fetchIzaApproved()

          createToast('Success Approve, 스테이킹을 실행해주세요', {
            type: 'success',
            position: 'bottom-center'
          })
          return
        }


        await stake(rawAmountStake.value, selectedMultiple.value)

        createToast('Transaction Success', {
          type: 'success',
          position: 'bottom-center'
        })
        await fetchIzaBalance()
        await fetchStakedIzaBalance()
        await fetchHolderApr()
        amountStake.value = ''
        selectedMultiple.value = null
      } catch (error) {
        createToast('Transaction Failed', {
          type: 'danger',
          position: 'bottom-center'
        })
      }
    }

    const unstakeBtnDisabled = computed(() => {
      if(!address.value)
        return true

      if(!amountUnstake.value)
        return true

      if(rawAmountUnstake.value.gt(unstakibleAmount.value))
        return true

      if(!selectedMultiple.value)
        return true

      return false
    })

    const unstakeActionText = computed(() => {
      if(!selectedMultiple.value)
        return `배율 선택`

      if(!amountUnstake.value)
        return `UNSTAKE`

      if(rawAmountUnstake.value.gt(unstakibleAmount.value))
        return `UNSTAKE 수량 초과`

      //if( sIzaApproved.value.quotient.lt(rawAmountUnstake.value))
      //  return `sIZA 전송 승인`


      return `UNSTAKE`
    })


    const onClickUnstake = async () => {
      invariant(selectedMultiple.value, '스테이킹 배율 선택')
      try {
        /*if( sIzaApproved.value.quotient.lt(rawAmountUnstake.value)) {
          await sIzaApprove()
          await fetchSIzaApproved()

          createToast('Success Approve, 언스테이킹을 실행해주세요', {
            type: 'success',
            position: 'bottom-center'
          })
          return
        }*/


        await unstake(rawAmountUnstake.value, selectedMultiple.value)

        createToast('Transaction Success', {
          type: 'success',
          position: 'bottom-center'
        })
        await fetchIzaBalance()
        await fetchStakedIzaBalance()
        await fetchHolderApr()
        amountUnstake.value = ''
        selectedMultiple.value = null
      } catch (error) {
        console.log(error)
        createToast('Transaction Failed', {
          type: 'danger',
          position: 'bottom-center'
        })
      }
    }

    const harvestBtnDisabled = computed(() => {
      if(!address.value)
        return true

      if(!stakeReward.value.gt(0))
        return true

      return false
    })

    const harvestActionText = computed(() => {
      if(!stakeReward.value.gt(0))
        return `HARVEST`

      return `HARVEST`
    })


    const onClickHarvest = async () => {
      try {

        await harvest()

        createToast('Transaction Success', {
          type: 'success',
          position: 'bottom-center'
        })
        await fetchIzaBalance()
        await fetchStakeReward()
      } catch (error) {
        createToast('Transaction Failed', {
          type: 'danger',
          position: 'bottom-center'
        })
      }
    }

    const onClickClaim = async () => {
      try {

        await claim()

        createToast('Transaction Success', {
          type: 'success',
          position: 'bottom-center'
        })
      } catch (error) {
        console.log(error)
        createToast('Transaction Failed', {
          type: 'danger',
          position: 'bottom-center'
        })
      }
    }
    const onSelectMultiple = (multiple: number) => {
      selectedMultiple.value = multiple
    }

    const onKeyupDecimal = (e:KeyboardEvent) => {
      const charCode = e.which ? e.which : e.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        e.preventDefault()
        return false
      } else {
        return true
      }
    }

    const aprByMultiple = computed(() => {
      if(!parseFloat(amountStake.value))
        return 0

      //console.log(baseAPR.value.toFixed(2))
      const share = rawAmountStake.value.mul(selectedMultiple.value?selectedMultiple.value:100).div(100)
      return new Percent(baseAPR.value.numerator.mul(share).div(stakeSupply.value.add(share)), rawAmountStake.value)
        //baseAPR.value.denominator.add(rawAmountStake.value.mul(100).div(selectedMultiple.value?selectedMultiple.value:100)))
      //return new Percent(baseAPR.value.numerator, baseAPR.value.denominator.add(rawAmountStake.value.mul(100).div(selectedMultiple.value?selectedMultiple.value:100)))
      //return baseAPR.value.add(new Percent(rawAmountStake.value.mul(selectedMultiple.value?selectedMultiple.value:100).div(100),Number(totalStakedIZABalance.value)?totalStakedIZABalance.value:BigNumber.from(100000000).mul(BigNumber.from(10).pow(18))))
    })

    const onClickStakeMax = () => {
      amountStake.value = izaFormattedBalance.value
    }

    const onClickUnstakeMax = () => {
      if(Number(formattedUnstakibleAmount.value))
        amountUnstake.value = formattedUnstakibleAmount.value
    }

    //const {holderAPRPct} = useAPRByAddress()

    onMounted(async () => {
      //await store.dispatch('dex/')
      await fetchStakeReward()
      await fetchClaimedInfo()
      await fetchTotalStakedIZA()
      onLoad.value = true
    })

    return {
      onLoad,
      izaFormattedBalance,
      sIzaFormattedBalance,
      formattedTotalStakedIZABalance,
      formattedStakeReward,
      formattedAccStakeReward,
      formattedClaimableUnstakeAmount,
      formattedClaimedUnstakeAmount,
      periods,
      amountStake,
      amountUnstake,
      onKeyupDecimal,
      estimatedClaimableDate,

      holderAPRPct,
      baseAPR,
      aprByMultiple,
      stakeBtnDisabled,
      stakeActionText,
      unstakeBtnDisabled,
      unstakeActionText,
      onClickStake,
      onClickUnstake,
      selectedMultiple,
      onSelectMultiple,
      formattedUnstakibleAmount,
      formattedStakedAmount,
      harvestBtnDisabled,
      onClickHarvest,
      harvestActionText,

      address,

      openStake,
      openUnstake,
      onClickOpenStake,
      onClickOpenUnstake,

      estimatedReceiveStakedToken,
      estimatedEndPeriod,

      onClickStakeMax,
      onClickUnstakeMax,
      onClickClaim,
    }
  }
})

export default StakeProvide
