

import {computed, defineComponent, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {RootState} from "@/store";
import useFractalCanvas from "@/composables/useFractalCanvas";

export default defineComponent({
  components: {},
  setup(props) {
    const store = useStore<RootState>()
    const refCanvas = ref<HTMLCanvasElement | null>(null);

    const interval = ref<any>(null)
    const internalInterval = ref<any>(null)
    onMounted(() => {
      window.addEventListener('load', async () => {
/*
        await store.dispatch('wallet/initialize')

        interval.value = setInterval(() => {
          store.dispatch('klaytn/checkNetworkStatus')
        }, 10000)
        internalInterval.value = setInterval(() => {
          store.dispatch('klaytn/increaseBlockNumber')
        }, 1000)
*/
        /*
        await store.dispatch('contract/setContract')
        await store.dispatch('contract/loadTokenMetadata')
        await store.dispatch('contract/loadMyBalance')
        await store.dispatch('privateSaleContract/setContract')
        await store.dispatch('privateSaleContract/syncSaleInfo')
        */
      })

      if (refCanvas.value) {
        const {loopStart} = useFractalCanvas(refCanvas.value)
        loopStart()
        //const canvas = refCanvas.value;

      }
    });

    return {
      refCanvas,
    }
  }
})

