import Dex from "@/store/dex/dex";
import moduleDex, {DexState} from "@/store/dex/dex";
import moduleWeb3, {Web3State} from "@/store/web3";
import { createStore } from "vuex"
import {getContractAt, getDeployment} from '@/sdk'
export interface IRootState {
  web3: Web3State
  dex: DexState
}
export class RootState implements IRootState{
  web3 = new Web3State()
  dex = new DexState()

}

export default createStore<RootState>({
  //state: new RootState(),
  mutations: {},
  actions: {},
  modules: {
    dex: moduleDex,
    web3: moduleWeb3
  },
  plugins: [

  ]
});
