
import LiquidityPoolItem from "@/components/LiquidityPoolItem.vue";
import {defineComponent} from "vue";

const LiquidityPoolList = defineComponent({
  name: "LiquidityPoolList",
  components: {LiquidityPoolItem},
  props: {
    addresses: {required:true,type:Array },
  },
  setup(props) {
    return {

    }
  }
})

export default LiquidityPoolList
