
import {computed, defineComponent, nextTick, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {RootState} from "@/store";
import {createToast} from "mosha-vue-toastify";

const Wallet = defineComponent({
  name: "Wallet",

  setup(props) {
    const store = useStore<RootState>()
    const disclaimerChecked = ref(false)
    const needConfirmDisclaimer = ref(false)


    const connectedAccount = computed( () => store.getters['web3/ADDRESS'])
    const connectBtnText = computed(() => {
      if(connectedAccount.value)
        return `${connectedAccount.value.slice(0,5)}...${connectedAccount.value.slice(-4)}`
      return 'Connect'
    })


    const confirmDisclaimer = () => {
      if(!disclaimerChecked.value) {
        createToast('안내사항을 확인해주세요.', {
          type: 'danger',
          position: 'bottom-center'
        })
        return
      }

      localStorage.setItem('confirmDisclaim', 'true')

      needConfirmDisclaimer.value = false
    }

    onMounted(() => {
      //
    })

    const connectWallet = async () => {
      /* if(store.getters['wallet/unSupported']) {
        createToast('Kaikas wallet can only be used on Google Chrome', {
          type: 'danger',
          position: 'bottom-center',
          showIcon: true,
        })
        return;
      }

      if(store.getters['wallet/needInstall']) {
        createToast('Need to install Kaikas wallet', {
          type: 'warning',
          position: 'bottom-center',
          showIcon: true,
        })
        //window.open('https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi?hl=ko', undefined)
        return;
      }
*/
      try {
        await store.dispatch('web3/connectWallet')
        const storedConfirmDisclaim = localStorage.getItem('confirmDisclaim')
        if (storedConfirmDisclaim !== 'true')
          needConfirmDisclaimer.value = true
      } catch (error) {
        createToast(error.toString(), {
          type: 'danger',
          position: 'bottom-center'
        })
      }

    }

    onMounted(async() => {
      window.addEventListener('load', async () => {
        await store.dispatch('web3/connectedWallet')
        if(connectedAccount.value) {
          const storedConfirmDisclaim = localStorage.getItem('confirmDisclaim')
          if (storedConfirmDisclaim !== 'true')
            needConfirmDisclaimer.value = true
        }
        //disclaimer modal cookie
      })

    })

    watch(connectedAccount, (newAccount, oldAccount) => {
      if(oldAccount &&!newAccount)
        createToast(`Wallet Disconnected`, {
          type: 'info',
          position: 'bottom-center',
          showIcon: true,
        })
      else
       createToast(`Connected ${newAccount}`, {
         type:'success',
         position: 'bottom-center',
          showIcon: true,
       })


    })


    return {
      connectedAccount,
      connectBtnText,
      connectWallet,
      disclaimerChecked,
      confirmDisclaimer,
      needConfirmDisclaimer,
    }
  }
})

export default Wallet
