import {WETH9} from "@/sdk/constants";
import {Klaytn} from "@/sdk/entities/Native";
import { AbstractCurrency } from './AbstractCurrency'
import { Currency } from './Currency'
import { validateAndParseAddress } from '@/sdk/functions/validateAndParseAddress'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends AbstractCurrency {
  public readonly address: string

  public readonly isNative: false = false
  public readonly isToken: true = true

  public constructor(address: string, decimals: number, symbol?: string, name?: string) {
    super(decimals, symbol, name)
    this.address = validateAndParseAddress(address)
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Currency): boolean {
    return other.isToken && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    return this.address.toLowerCase() < other.address.toLowerCase()
  }

  /**
   * Return this token, which does not need to be wrapped
   */
  public get wrapped(): Token {
    return this
  }

  /**
   * Return logo
   */
  // public get logo(): string | null {
  //   return this.chainId in CHAIN_KEY ? `https://raw.githubusercontent.com/sushiswap/logos/main/network/${CHAIN_KEY[this.chainId]}/.jpg` : undefined
  // }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB)
  } else if (currencyA instanceof Token) {
    return false
  } else if (currencyB instanceof Token) {
    return false
  } else {
    return currencyA === currencyB
  }
}


export interface SerializedToken {
  symbol: string,
  name: string,
  decimals: number,
}

export function deserializeToken(address: string, serializedToken: SerializedToken): Token {
  //if(address === WETH9.address)
    //return new Klaytn()


  return new Token(
    address,
    serializedToken.decimals,
    address === WETH9.address ? WETH9.symbol : serializedToken.symbol,
    serializedToken.name
  )
}
