
import {getDeployment} from "@/sdk";
import Caver from "caver-js";
import {ethers} from "ethers";
import invariant from 'tiny-invariant'

declare let window: any

const useWeb3Provider = () => {
  const { klaytn } = window

  //invariant(klaytn, 'NEED_INSTALL_KAIKAS')

  //const walletProvider = new ethers.providers.Web3Provider(klaytn)
  //const rpcProvider = new ethers.providers.JsonRpcProvider(process.env.VUE_APP_RPC_URL!)

  /*;(async () => {
    if(await klaytn._kaikas.isApproved()) {
      await connectWallet()
    }
  })()*/

  const address = klaytn ? klaytn.selectedAddress : ''

  const provider = klaytn ? new ethers.providers.Web3Provider(klaytn) : new ethers.providers.JsonRpcProvider(process.env.VUE_APP_RPC_URL!)


  return {
    provider,
    address
  }
}

export const useWallet = async () => {
  const { klaytn } = window

  invariant(klaytn, 'NEED_INSTALL_KAIKAS')

  return {
    wallet: klaytn
  }
}

export const connectWallet = async () => {
  const { klaytn } = window

  invariant(klaytn, 'NEED_INSTALL_KAIKAS')

  try {
    const accounts = await klaytn.enable()

    return {
      account: accounts[0]
    }
  } catch (error) {
    return {
      account: ''
    }
  }
}

export default useWeb3Provider
