
import SelectedToken from "@/components/swap/SelectedToken.vue";
import Wallet from "@/components/Wallet.vue";
import {
  useApprovalTokenToRouter,
  useApprovalTokenToRouterRef,
  useTokenBalance,
  useTokenBalanceRef
} from "@/composables/useTokenBalance";
import {useTrade} from "@/composables/useTrade";
import {useV2TradeExactOut} from "@/sdk/api/dex";
import {WETH9} from "@/sdk/constants";
import {CurrencyAmount, Pair, Token} from "@/sdk/entities";
import {TradeType} from "@/sdk/enums";
import {RootState} from "@/store";
import MultiSelect from '@vueform/multiselect';
import {BigNumber, ethers} from "ethers";
import {createToast} from "mosha-vue-toastify";
import {computed, defineComponent, nextTick, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";

const TokenSwap = defineComponent({
  name: "TokenSwap",
  components: {
    Wallet,
    SelectedToken,

  },
  props: {
    fromAddress: {
      type: String,
    },
    toAddress: {
      type: String,
    }
  },
  setup(props, {emit}) {
    const store = useStore<RootState>()
    const tokens = computed<Array<Token>>(() => store.getters['dex/token/TOKENS'])
    const pairs = computed<Array<Pair>>(() => store.getters["dex/pair/ALL_PAIR"])

    const selectedFromToken = ref(props.fromAddress)
    const selectedToToken = ref(props.toAddress)
    const amountFrom = ref('')
    const amountTo = ref('')
    const rawAmountFrom = computed(() => CurrencyAmount.fromRawAmount(fromToken.value, ethers.utils.parseUnits(Number(amountFrom.value).toFixed(fromToken.value.decimals), fromToken.value.decimals)))
    const rawAmountTo = computed(() => CurrencyAmount.fromRawAmount(toToken.value, ethers.utils.parseUnits(Number(amountTo.value).toFixed(toToken.value.decimals), toToken.value.decimals)))

    const tokenBalances = computed<{[address:string]:CurrencyAmount<Token>}>(() => store.getters["dex/token/TokenBalances"])

    const tempSlippage = ref(5)
    const customSlippage = ref('')
    const slippages = ref([0.3,0.5,1,5])

    const fromToken = computed(() => store.getters['dex/token/TokenByAddress'](selectedFromToken.value))
    const toToken = computed(() => store.getters['dex/token/TokenByAddress'](selectedToToken.value))
    const tokensForSelector = computed(() => {
      return tokens.value.map(v => {
        return {
          value: v.address,
          label: v.symbol,
          token: v
        }
      })
    })


    const { formatted: fromTokenFormattedBalance, raw: fromTokenRawBalance, fetchBalance: fetchFromTokenBalance } = useTokenBalanceRef(fromToken)
    const { formatted: toTokenFormattedBalance, raw: toTokenRawBalance, fetchBalance: fetchToTokenBalance } = useTokenBalanceRef(toToken)
    const { raw: fromTokenApproved, approve: fromTokenApprove, fetchApproved: fetchFromTokenApproved } = useApprovalTokenToRouterRef(fromToken)

    const {
      updateAmountFrom,
      updateAmountTo,
      rawAmount,
      rawCurrency,
      formattedCurrencyAmount,

      doSwap,
      bestTrade,
      trade_mode,
      priceImpact,
      executionPrice,
      estimatedReceive,
      realizedLPFee,
      priceImpactWithoutFeeFraction,
      allowedSlippage,
      updateSlippage,
      isExactInput,
      slippageAdjustedAmounts
    } = useTrade(fromToken, toToken)

    const defaultExchangeTrade = computed(() => {
      if(fromToken.value && toToken.value)
      return useV2TradeExactOut(
        fromToken.value,
        CurrencyAmount.fromRawAmount(toToken.value, BigNumber.from(1).mul(BigNumber.from(10).pow(toToken.value.decimals))),
        {maxHops: 3}
      )
      return undefined
    })

    const exchangeRoutes = computed(() => {
      if(defaultExchangeTrade.value) {
        return defaultExchangeTrade.value.route.path.map(v=>v.symbol).join(' > ')
      }

      return 'NOT'
    })

    const onKeyupDecimal = (e:KeyboardEvent) => {
      const charCode = e.which ? e.which : e.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        e.preventDefault()
        return false
      } else {
        return true
      }
    }

    const onInputAmountFrom = () => {
      updateAmountFrom(amountFrom.value)
      amountTo.value = rawCurrency.value.toSignificant()
    }
    const onInputAmountTo = () => {
      updateAmountTo(amountTo.value)
      amountFrom.value = rawCurrency.value.toSignificant()
    }

    const onClickInputMax = () => {
      if(!Number(fromTokenRawBalance.value.quotient))
        return
      amountFrom.value = fromTokenRawBalance.value.toSignificant(6)
      updateAmountFrom(amountFrom.value)
      amountTo.value = rawCurrency.value.toSignificant()
    }

    const onClickOutputMax = () => {
      if(!Number(toTokenRawBalance.value.quotient))
        return
      amountTo.value = toTokenRawBalance.value.toSignificant(6)
      updateAmountTo(amountTo.value)
      amountFrom.value = rawCurrency.value.toSignificant()
    }

    const onInputCustomSlippage = () => {
      if(!/^[0-9]*[.,]?[0-9]{0,2}$/.test(customSlippage.value)) {
        customSlippage.value = (Math.floor(100*parseFloat(customSlippage.value))/100).toString()
      }
      if(Number(customSlippage.value) > 50)
        customSlippage.value = '50'
      tempSlippage.value = Math.min(50,Number(customSlippage.value))
    }

    const onClickInvert = () => {
      const addrs = {
        from: props.toAddress+'',
        to: props.fromAddress+'',
        fromAmount: amountTo.value,
        toAmount: amountFrom.value,
      }

      emit('update:fromAddress', addrs.from)
      emit('update:toAddress', addrs.to)
      selectedFromToken.value = addrs.from
      selectedToToken.value = addrs.to

      nextTick(() => {
        ///if(bestTrade.value) {
          if (trade_mode.value === TradeType.EXACT_INPUT) {
            updateAmountTo(addrs.toAmount)
            amountTo.value = addrs.toAmount
            amountFrom.value = bestTrade.value?bestTrade.value.inputAmount.toSignificant():''
//            amountFrom.value = ethers.utils.formatEther(rawAmount.value)
          } else {
            updateAmountFrom(addrs.fromAmount)
            amountFrom.value = addrs.fromAmount
            amountTo.value = bestTrade.value?bestTrade.value.outputAmount.toSignificant():''
            //amountTo.value = ethers.utils.formatEther(rawAmount.value)
          }

        ////}
      })
    }


    const openConfigPopup = ref(false)
    const openTokenSelectPopup = ref(false)
    const toggleConfigPopup = () => {
      if(openConfigPopup.value) {
        updateSlippage(tempSlippage.value)
      }
      openConfigPopup.value = !openConfigPopup.value
    }
    const hideTokenSelectPopup = () => {
      openTokenSelectPopup.value = false
    }
    const showTokenSelectPopup = (isInput:boolean) => {
      tokenSelectIsInputMode.value = isInput
      openTokenSelectPopup.value = true
    }
    const tokenSelectIsInputMode = ref(false)

    const tokenListForSelect = computed(() => {
      const list = new Array<Token>()
      list.push(fromToken.value)
      list.push(toToken.value)
      list.push(...tokens.value.filter(v=>v.address !== selectedFromToken.value && v.address !== selectedToToken.value))
      return list
    })

    const classesForTokenList = (address:string) => {
      if(tokenSelectIsInputMode.value) {
        if(address == selectedFromToken.value)
          return ['selected','disabled']
        if(address == selectedToToken.value)
          return ['selected']

      } else {
        if(address == selectedFromToken.value)
          return ['selected']
        if(address == selectedToToken.value)
          return ['selected', 'disabled']
      }

      return []
    }

    const selectToken = (address:string) => {
      if(tokenSelectIsInputMode.value) {
        if(selectedFromToken.value === address)
          return

        selectedFromToken.value = address;
      } else {
        if(selectedToToken.value === address)
          return
        selectedToToken.value = address;
      }
      hideTokenSelectPopup()
    }

    const changeSlippage = (slippage:number) => {
      tempSlippage.value = slippage
      customSlippage.value = ''
      //updateSlippage(slippage)
    }

    watch(selectedFromToken, (nv, ov) => {
      if(ov && selectedToToken.value === nv) {
        onClickInvert()
        return
      }
      if(selectedFromToken.value !== props.fromAddress) {
        emit('update:fromAddress', selectedFromToken.value)
        //updateAmountFrom(amountFrom.value)
        if(isExactInput.value) {
          updateAmountFrom(amountFrom.value)
          amountTo.value = bestTrade.value?bestTrade.value.outputAmount.toSignificant():''
          //amountTo.value = rawCurrency.value.toSignificant()
        } else {
          updateAmountTo(amountTo.value)
          amountFrom.value = bestTrade.value?bestTrade.value.inputAmount.toSignificant():''
          //amountFrom.value = rawCurrency.value.toSignificant()
        }
        //amountFrom.value = bestTrade.value?bestTrade.value.inputAmount.toSignificant():''
        //amountTo.value = bestTrade.value?bestTrade.value.outputAmount.toSignificant():''
      }
    })
    watch(selectedToToken, (nv, ov) => {
      if(ov && selectedFromToken.value === nv) {
        onClickInvert()
        return
      }
      if(selectedToToken.value !== props.toAddress) {
        emit('update:toAddress', selectedToToken.value)

        if(isExactInput.value) {
          updateAmountFrom(amountFrom.value)
          amountTo.value = bestTrade.value?bestTrade.value.outputAmount.toSignificant():''
        //  updateAmountFrom(amountFrom.value)
          //amountTo.value = rawCurrency.value.toSignificant()
        } else {
          updateAmountTo(amountTo.value)
          amountFrom.value = bestTrade.value?bestTrade.value.inputAmount.toSignificant():''
        //  updateAmountTo(amountTo.value)
          //amountFrom.value = rawCurrency.value.toSignificant()
        }
      }
    })

    const onLoaded = ref(false)

    onMounted(async () => {
      selectedFromToken.value = tokens.value[1].address
      selectedToToken.value = tokens.value[0].address
      emit('update:fromAddress', selectedFromToken.value)
      emit('update:toAddress', selectedToToken.value)
      onLoaded.value = true

      await store.dispatch('dex/token/fetchTokenBalances')
    })

    const walletConnected = computed(() => store.getters['wallet/connected'])
    const connectedAccount = computed( () => store.getters['web3/ADDRESS'])

   /* const connectWallet = async () => {

      try {
        await store.dispatch('web3/connectWallet')
        const storedConfirmDisclaim = localStorage.getItem('confirmDisclaim')

      } catch (error) {
        createToast(error.toString(), {
          type: 'danger',
          position: 'bottom-center'
        })
      }

    }*/

    const swapBtnDisabled = computed(() => {
      if(!amountFrom.value || !amountTo.value)
        return true
      if(amountFrom.value && rawAmountFrom.value.greaterThan(fromTokenRawBalance.value))
        return true

      //if(amountFrom.value && props.fromAddress != WETH9.address && fromTokenApproved.value.lessThan(rawAmountFrom.value))
      //  return true

      if(parseFloat(priceImpactWithoutFeeFraction.value?.toSignificant()) > allowedSlippage.value)
        return true

      if(!bestTrade.value)
        return true

      return false
    })
    const swapActionText = computed(() => {
      if(!amountFrom.value || !amountTo.value)
        return `SWAP`

      if(amountFrom.value && rawAmountFrom.value.greaterThan(fromTokenRawBalance.value))
        return `${fromToken.value.symbol} 토큰 잔고 부족`

      if(amountFrom.value && props.fromAddress != WETH9.address && fromTokenApproved.value.lessThan(rawAmountFrom.value))
        return `${fromToken.value.symbol} 전송 승인`

      if(parseFloat(priceImpactWithoutFeeFraction.value?.toSignificant()) > allowedSlippage.value)
        return `Price Impact too high`

      if(!bestTrade.value)
        return `거래 가능 경로 없음`

      return `SWAP`
    })

    const onClickSwap = async () => {
      try {

        if(amountFrom.value && props.fromAddress != WETH9.address && fromTokenApproved.value.lessThan(rawAmountFrom.value)) {
          await fromTokenApprove()
          await fetchFromTokenApproved()

          createToast('Success Approve, 스왑을 실행해주세요', {
            type: 'success',
            position: 'bottom-center'
          })
          return
        }

        await doSwap()

        createToast('Transaction Success', {
          type: 'success',
          position: 'bottom-center'
        })
        await fetchToTokenBalance()
        await fetchFromTokenBalance()
        amountFrom.value = ''
        amountTo.value = ''
      } catch (error) {

        createToast('Transaction Failed', {
          type: 'danger',
          position: 'bottom-center'
        })
      }
    }

    return {
      swapBtnDisabled,
      swapActionText,
      connectedAccount,
      walletConnected,
     // connectWallet,
      onLoaded,
      defaultExchangeTrade,
      exchangeRoutes,
      tokens,
      selectedFromToken,
      selectedToToken,
      tokensForSelector,
      fromToken,
      toToken,
      amountFrom,
      amountTo,
      formattedCurrencyAmount,
      estimatedReceive,
      onKeyupDecimal,
      onInputAmountFrom,
      onInputAmountTo,
      priceImpact,
      executionPrice,
      realizedLPFee,
      priceImpactWithoutFeeFraction,
      allowedSlippage,
      slippageAdjustedAmounts,
      onClickInvert,
      openConfigPopup,
      toggleConfigPopup,
      changeSlippage,
      onInputCustomSlippage,
      customSlippage,
      tempSlippage,
      slippages,
      isExactInput,
      openTokenSelectPopup,
      showTokenSelectPopup,
      hideTokenSelectPopup,
      tokenListForSelect,
      classesForTokenList,
      selectToken,
      onClickSwap,
      fromTokenFormattedBalance,
      toTokenFormattedBalance,

      onClickInputMax,
      onClickOutputMax,

      tokenBalances
    }
  }
})

export default TokenSwap;
