
import StakeProvide from "@/components/stake/StakeProvide.vue";
import {defineComponent} from "vue";

const Stake = defineComponent({
  name: "Stake",
  components: {StakeProvide},
  setup(props) {
    return {

    }
  }
})

export default Stake
