type MessageContext = {

  named: (key: string) => any,

};

const languages = {
  ko: {
    selected_locale: {
      ko: 'KO',
      en: 'EN'
    },
    locale_selector: {
      ko: 'Korean',
      en: 'English',
    },
    page_common: {
      title_innovate: 'MOO Finance<br>Innovate<br>NEW Finance',
      content_innovate: '무파이낸스는 글로벌 탈중앙화 금융 서비스로 블록체인 여신 서비스의 미래를 선두하기위해<br>자동화된 스마트 컨트랙트를 통한 계약과 여신 온라인 서비스 인프라를 제공합니다',
      content_service: '우리의 여신 서비스 이용자들은 가상자산의 보유량을 유지하면서도<br>가상자산을 담보로 한 현금 차용으로 추가 유동성을 확보할 수 있습니다.<br><br>가상자산은 변동성이 크기 때문에 우리는 적절한 자본 운영과 동시에<br>생태계 확장에 집중하고, 글로벌 거래소들과 협업하여<br>꾸준한 유동성과 거래량 상승을 도모합니다.',
    },
    page_top: {
      moo_is_full: '무에서 유, 유에서 풀소유',
      owned_pool: '나의 디파이 풀을 소유하세요',
      moo_is_defi: '무파이는 글로벌 가상자산 담보 대출 서비스를 탑재한 탈중앙화 금융 서비스입니다',
      moo_is_defi2: '글로벌 가상자산 대출 & 멀티체인 디파이 2.0 프로젝트',
    },
    page_sale: {
      what_iza: 'IZA는 무파이의 거버넌스 토큰입니다',
      watch_below: '아래에서 사전 교환 기간 및 총 교환수량을 확인해주세요',
      sale_start_before: (ctx:MessageContext) => `사전 교환은 #${ctx.named('block')} 블록에 시작됩니다 ( ${ctx.named('remainBlock')} 블록 남음)`,
      sale_start_after: (ctx:MessageContext) => `사전 교환이 #${ctx.named('block')} 블록부터 진행중입니다`,
      total_exchanged_amount: '총 교환량',
      my_klay_balance: '내 지갑',
      referral_code: '초대 코드',
      referral_fee: (ctx:MessageContext) => `입력 시 구매자 (+${ctx.named('recipient')}%), 추천인 (+${ctx.named('invitor')}%)`,
      exchange: '교환',
      private_sale_additional: '사전 교환 추가 지급 (x2)',
      referral_bonus: '초대 코드 추가 지급',
      total_receive: '총 수령',
    },
    contract_detail: {
      my_referral_code: '내 초대 코드',
      obtain_count: '적립횟수',
      sum_receive_and_obtain: '누적수령 / 누적적립',
      obtainable: '수령가능',
      code_generated_after_sale: '세일, 에어드랍 참여 후 초대 코드가 생성됩니다',
      private_sale_amount: 'Pre Sale Amount (사전 교환 수량)',
      additional_multiply: 'Additional Multiply (추가 지급)',
      additional_multiply_ex: (ctx:MessageContext) => `사전 교환 기간 내 ${ctx.named('amount')} IZA교환 시 ${ctx.named('mul_amount')} IZA를 수령하게 됩니다`,
      my_token_balance: `My IZA Balance (내 IZA)`,

    },

    watch_token_in_wallet: '지갑에 IZA 토큰추가',
    total_supply: '총 발행량',
    circulating_supply: '유통량',

    disclaimer: {
      title: '주의사항',
      content1: '본 웹사이트에 공시된 일체의 정보는 투자 자문, 재무 자문, 거래 자문 또는 기타 다른 조언을 제공하지 않습니다',
      content2: '2nd Pre Sale은 2,000,000,000 IZA 하드캡에 도달 시 종료됩니다',
      content3: '서비스 런칭 이전 구매하거나 보유한 토큰은 추후 서비스 런칭일정에 따른 락업이 설정됩니다',
      content4: '투자에 따른 손실을 비롯한 그 외의 피해가 발생하여도 팀은 보상, 책임 및 기타 의무를 지지 않습니다. 투자의 책임은 투자자 본인에게 있습니다',
      content5: '무파이낸스는 재량에 따라 사이트에 수정사항을 게시하여 언제든지 본 사항을 수정할 수 있습니다',
    },

    airdrop: {
      content1: 'Kaikas 지갑 연동 후 IZA토큰 Claim이 가능합니다',
      content2: 'Airdrop 이벤트 참여자가 아닐 경우 Claim이 불가합니다',
    },
    referral: {
      content1: 'Referral code는 세일참여자와 에어드랍참여자에게만 생성됩니다',
      content2: '레퍼럴 링크를 통해 접속하여 세일 참여 발생 시',
      content3: '세일참여자와 레퍼럴오너에게 5%씩 추가 IZA가 적립됩니다',
    }

  },
  en: {
    selected_locale: {
      ko: 'KO',
      en: 'EN'
    },
    locale_selector: {
      ko: 'Korean',
      en: 'English',
    },
    page_common: {
      title_innovate: 'MOO Finance<br>Innovate<br>NEW Finance',
      content_innovate: 'MOO Finance is global decentralized financial services lead the future of blockchain loan service Contracts through automated smart contracts, It provides credit online service infrastructure',
      content_service: 'Our credit service users can time as borrowing cash they need immediately with maintaining the number of virtual assets It seeks true decentralize as a loans.<br><br>Virtual assets are highly volatile, we focus on collaborating with global exchanges to promote steady liquidity and expanding the ecosystem with proper capital management',
    },
    watch_token_in_wallet: 'Watch IZA in wallet',
    total_supply: 'Total Supply',
    circulating_supply: 'Circulating Supply',

    page_top: {
      moo_is_full: 'Pool to Rich, Rich to Super rich',
      owned_pool: 'Own My DeFi Pool',
      moo_is_defi: 'Moofi is a decentralized financial service equipped with a global virtual asset loan service',
      moo_is_defi2: 'Global crypto lending protocol & Multichain Defi 2.0 project',
    },
    page_sale: {
      what_iza: 'IZA is Moofi\'s Governance Token',
      watch_below: 'Check the Exchange period and total exchange quantity below.',
      sale_start_before: (ctx:MessageContext) => `Exchange starts at block #${ctx.named('block')} ( block ${ctx.named('remainBlock')} left )`,
      sale_start_after: (ctx:MessageContext) => `Exchange is in progress from block #${ctx.named('block')}`,
      total_exchanged_amount: 'Total Exchanged',
      my_klay_balance: 'Balance',
      referral_code: 'Referral code',
      referral_fee:  (ctx:MessageContext) => `Recipient (+${ctx.named('recipient')}%), Invitor (+${ctx.named('invitor')}%) when Referral`,
      exchange: 'Exchange',
      private_sale_additional: 'Private Sale Additional (x2)',
      referral_bonus: 'Referral code Additional',
      total_receive: 'Total Receive',
    },

    contract_detail: {
      my_referral_code: 'My Referral',
      obtain_count: 'Accumulated count',
      sum_receive_and_obtain: 'Cumulative claim/accumulation',
      obtainable: 'Claimable',
      code_generated_after_sale: 'After participating in the sale or airdrop, an referral code is generated',
      private_sale_amount: '2nd Pre Sale Amount',
      additional_multiply: 'Additional Multiply Rate',
      additional_multiply_ex: (ctx:MessageContext) => `When you exchange ${ctx.named('amount')} IZA, you will receive ${ctx.named('mul_amount')} IZA`,
      my_token_balance: `My IZA Balance`,
    },

    disclaimer: {
      title: 'Disclaimer',
      content1: 'The information provided on this website does not constitute investment advice, financial advice, trading advice, or any other sort of advice',
      content2: 'The 2nd Pre Sale will be finished when it reaches the hardcap of 2,000,000,000 IZA',
      content3: 'After Liquidity pools are opened, pools of tokens locked in smart contracts that provide liquidity in decentralized exchanges in an attempt to attenuate the problems caused by the illiquidity typical of such systems',
      content4: 'Team or Foundation do not accept any responsibility and will not be liable for investment decisions you make based on the information provided on this website',
      content5: 'Moo Finance may amend the terms of use at any time in its discretion, by posting disclaimers on the Site',
    },

    airdrop: {
      content1: 'Airdrop claim is possible after linking the Kaikas wallet',
      content2: 'and Airdrop is not possible if you are not a participant in the airdrop event',
    },
    referral: {
      content1: 'The Referral code is created only for private sale participants and Airdrop event participants',
      content2: 'When you purchase via the referral link,',
      content3: 'an additional IZA of 5% will be accumulated for buyers and referral code owners',
    }
  }
}


export default languages
