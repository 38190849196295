

import {useDatafeed} from "@/composables/useDatafeed";
import {useSimpleTrade, useV2TradeExactOut} from "@/sdk/api/dex";
import {CurrencyAmount, Price, Trade} from "@/sdk/entities";
import {RootState} from "@/store";
import {BigNumber} from "ethers";
import {UDFCompatibleDatafeed} from "public/datafeeds/udf/src/udf-compatible-datafeed";
import {useStore} from "vuex";

//import {UDFCompatibleDatafeed} from "public/datafeeds/udf/src/udf-compatible-datafeed";
import {
  ChartingLibraryWidgetOptions, IChartingLibraryWidget, LanguageCode,
  ResolutionString,
  TradingTerminalWidgetOptions,
  widget
} from '../../public/charting_library';

//import { Datafeeds } from '../../public/datafeeds/udf'
import {computed, defineComponent, nextTick, onMounted, onUnmounted, ref, watch} from "vue";

function getLanguageFromURL(): LanguageCode | null {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
}
declare let window: any

const TokenChart = defineComponent({
  name: "TokenChart",
  props: {
    symbol: {
      default: 'tIZA/KLAY',
      type: String,
    },
    interval: {
      default: '60',
      type: String,
    },
    datafeedUrl: {
      default: 'https://demo_feed.tradingview.com',
      type: String,
    },
    libraryPath: {
      default: '/charting_library/',
      type: String,
    },
    chartsStorageUrl: {
      default: 'https://saveload.tradingview.com',
      type: String,
    },
    clientId: {
      default: 'moo.loans',
      type: String,
    },
    userId: {
      default: 'public_user_id',
      type: String,
    },
    studiesOverrides: {
      type: Object,
      required: false,
    },

    fromAddress: {
      type: String,
    },
    toAddress: {
      type: String,
    }
  },
  setup(props) {
    const store = useStore<RootState>()
    const chartContainer = ref()
    const tvWidget = ref<IChartingLibraryWidget|null>(null)

    const fromToken = computed(() => store.getters['dex/token/TokenByAddress'](props.fromAddress))
    const toToken = computed(() => store.getters['dex/token/TokenByAddress'](props.toAddress))

    const { v2Trade } = useSimpleTrade()
    /*const defaultExchangeTrade = computed(() => {
      if(fromToken.value && toToken.value)
        return v2Trade(
          fromToken.value,
          CurrencyAmount.fromRawAmount(toToken.value, BigNumber.from(1).mul(BigNumber.from(10).pow(toToken.value.decimals))),
          {maxHops: 3}
        )
      return undefined
    })*/
    const defaultExchangeTrade = ref<Trade<any, any, any> | undefined>(undefined)
    const chartFrom = ref('')
    const chartTo = ref('')
    //const ticker = computed(() => `Moofi:${props.symbol}`)
    const ticker = computed(() => `Moofi:${chartFrom.value}/${chartTo.value}`)

    const exchangePrice = computed(() => {
      if(!defaultExchangeTrade.value)
        return 'UNKNOWN'

      const {numerator, denominator} = defaultExchangeTrade.value.route.midPrice
      const price = new Price(fromToken.value, toToken.value, denominator, numerator)

      return price.toSignificant()
    })

    const { Datafeed } = useDatafeed()
    const chartInterval = ref<any>(null)
    /*
    * "left_toolbar", "header_widget", "timeframes_toolbar", "edit_buttons_in_legend", "context_menus", "control_bar", "border_around_the_chart"
    * */
    onMounted(() => {
      nextTick(() => {
      const widgetOptions: ChartingLibraryWidgetOptions | TradingTerminalWidgetOptions = {
        symbol: ticker.value,
        // BEWARE: no trailing slash is expected in feed URL
        //datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(props.datafeedUrl),
        datafeed: Datafeed(),
        interval: props.interval as ResolutionString,
        container: chartContainer.value,
        library_path: props.libraryPath,
        locale: 'ko',
        disabled_features: [
          "header_symbol_search", "header_compare", "symbol_search_hot_key", "timeframes_toolbar", 'symbol_info','volume_force_overlay',

        ],
        enabled_features: ["hide_resolution_in_legend"],
        client_id: props.clientId,
        user_id: props.userId,
        fullscreen: false,
        theme: 'Dark',
        autosize: true,
        timezone: "Asia/Seoul",
        studies_overrides: {},
      }
      tvWidget.value = new widget(widgetOptions);
      chartInterval.value = setInterval(() => {
        nextTick(() => {
          if(tvWidget.value !== null)
            tvWidget.value.activeChart().resetData()
        })
      }, 60000)
      })
    })
    onUnmounted(() => {
      if(tvWidget.value !== null) {
        tvWidget.value.remove()
        tvWidget.value = null
      }
      if(chartInterval.value !== null) {
        clearInterval(chartInterval.value)
      }
    })

    watch(()=>props.symbol, () => {
      //console.log(props.symbol)
        defaultExchangeTrade.value = v2Trade(
          fromToken.value,
          CurrencyAmount.fromRawAmount(toToken.value, BigNumber.from(1).mul(BigNumber.from(10).pow(toToken.value.decimals))),
          {maxHops: 3}
        )
      if(defaultExchangeTrade.value !== undefined) {
        chartFrom.value = defaultExchangeTrade.value.route.path[0].symbol || ''
        chartTo.value = defaultExchangeTrade.value.route.path[1].symbol || ''

        if(tvWidget.value !== null)
          tvWidget.value.setSymbol(`Moofi:${chartFrom.value}/${chartTo.value}`, tvWidget.value.symbolInterval().interval, () => { /**/
        })
      }
        //console.log(defaultExchangeTrade.value)
    }, {
      immediate: true
    })

    return {
      chartContainer,
      exchangePrice,
    }
  }
})

export default TokenChart;
