import {getDeployment} from "@/sdk";
import { ChainTokenMap, TokenMap } from '@/sdk/types'
import { SUSHI_ADDRESS, USDT_ADDRESS, WETH9_ADDRESS, WNATIVE_ADDRESS } from './addresses'

import { ChainId } from '@/sdk/enums'
import { Token } from '@/sdk/entities/Token'

export const WETH9 = new Token(getDeployment('WETH9').address, 18, 'KLAY', 'Klaytn')

export const WNATIVE = WETH9

const network_id = parseInt(process.env.VUE_APP_NETWORK_ID!)

export const GOVERNANCE =
  process.env.NODE_ENV === 'development'
    ? new Token('0x316676DF9DD4bDfbDCED5e16f70314E85565C80a', 18, 'tIZA', 'Test IZA')
    : new Token('0x12981F2827aEe18188E861493eA6540B4cE5404A', 18, 'IZA', 'Moo Finance Protocol')

export const USDT =
  process.env.NODE_ENV === 'development'
    ? new Token(USDT_ADDRESS[network_id], 6, 'tUSDT', 'Test USDT')
    : new Token(USDT_ADDRESS[network_id], 6, 'KUSDT', 'Orbit Bridge Klaytn USD Tether')

export const STABLE = USDT
