import {getAllPairs} from "@/sdk/api/dex";
import {Pair} from "@/sdk/entities";
import invariant from "tiny-invariant";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store";

interface IPair {
  token0: string,
  token1: string
}

interface IPairList {
  [address:string]: IPair
}

export class PairState {
  public pairs = new Array<Pair>()

}

const mutations:MutationTree<PairState> = {
  SET_PAIRS(state, payload) {
    state.pairs = payload
  }
}

const actions:ActionTree<PairState, RootState> = {
  setContract(context) {
    //
  },
  async loadPairs({ commit, getters, rootGetters }) {

    const pairs = await getAllPairs(rootGetters["dex/token/TOKENS"])

    commit('SET_PAIRS', pairs)
  },

  async addLiquidity({ commit, getters, rootGetters}, payload) {
    //
  },

  async removeLiquidity({ commit, getters, rootGetters}, payload) {
    //
  }



}

const getters: GetterTree<PairState, RootState> = {

  IS_OWNER(state, getters, rootState, rootGetters) {
    return true
  },
  PAIR_ADDRESSES(state): string[] {
    return state.pairs.map(v=>v.liquidityToken.address)
  },
  ALL_PAIR(state, getters, rootState, rootGetters) {
    return state.pairs
  },
  GET_PAIR(state) {
    return (address: string): Pair => {
      const pair = state.pairs.find(v=>v.liquidityToken.address===address)
      invariant(pair, 'INVARIANT_PAIR')
      return pair
    }
  }

}


const modulePair: Module<PairState, RootState> = {
  namespaced: true,
  state: new PairState(),
  actions,
  mutations,
  getters
}

export default modulePair
