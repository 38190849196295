import {useTotalSupply} from "@/composables/useTokenBalance";
import useWeb3Provider from "@/composables/useWeb3Provider";
import {useUSDTPrice, useV2TradeExactOut} from "@/sdk/api/dex";
import {STABLE, USDT} from "@/sdk/constants";
import {CurrencyAmount, Pair, Price, Token} from "@/sdk/entities";
import {RootState} from "@/store";
import {ethers} from "ethers";
import {ref} from "vue";
import {useStore} from "vuex";


declare let window: any


export const useLPInfo = (pair: Pair) => {
  const { provider } = useWeb3Provider()
  const store = useStore<RootState>()

  const pairValueLocked = ref<number>(0)
  const pairPrice = ref<number>(0)



  const fetchLPInfo = async () => {
    async function getLPInfo() {
      const token0USDPrice = useUSDTPrice(pair.token0)
      const token1USDPrice = useUSDTPrice(pair.token1)


      //const amountOut = CurrencyAmount.fromRawAmount(USDT, ethers.utils.parseEther('1'))

      if(!token0USDPrice || !token1USDPrice)
        return {
          _pairValueLocked: 0,
          _pairPrice: 0
        }

      const {raw: lpSupply, fetchSupply: fetchLPSupply} = useTotalSupply(pair.liquidityToken)
      await fetchLPSupply()


      const _pairValueLocked = parseFloat(token0USDPrice.quote(pair.reserve0).toSignificant())+parseFloat(token1USDPrice.quote(pair.reserve1).toSignificant())
      const _pairPrice =  _pairValueLocked/parseFloat(lpSupply.value.toFixed())

      return {
        _pairValueLocked,
        _pairPrice,
      }
    }

    const lpInfo = await getLPInfo()
    pairValueLocked.value = lpInfo._pairValueLocked
    pairPrice.value = lpInfo._pairPrice
  }


  ;(async () => {
    await fetchLPInfo()
  })()


  return {
    pairValueLocked,
    pairPrice,
  }
}
