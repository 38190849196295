
import LiquidityProvide from "@/components/LiquidityProvide.vue";
import LiquidityStake from "@/components/LiquidityStake.vue";
import {useLPInfo} from "@/composables/useLiquidity";
import {useLPDistributor, useOldLPDistributor, useTokenBalance} from "@/composables/useTokenBalance";
import {GOVERNANCE} from "@/sdk/constants";
import {Pair, Token} from "@/sdk/entities";
import {RootState} from "@/store";
import {createToast} from "mosha-vue-toastify";
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";

const LiquidityPoolItem = defineComponent({
  name: "LiquidityPoolItem",
  components: {LiquidityStake, LiquidityProvide},
  props: {
    address: {required: true},
  },
  setup(props) {
    const store = useStore<RootState>()
    const pair = computed<Pair>(() => store.getters["dex/pair/GET_PAIR"](props.address))
    const token0 = computed<Token>(() => pair.value.token0)
    const token1 = computed<Token>(() => pair.value.token1)
    const governanceAddress = computed(()=>GOVERNANCE.address)
    const {  rawStakedLPBalance, formattedStakedLPBalance, fetchStakedLPBalance, fetchPoolApr, poolAPR } = useLPDistributor(pair.value)
    const oldLPDistributor = useOldLPDistributor(pair.value)

    const rawStakedOldLP = computed(() => oldLPDistributor.rawOldStakedLPBalance.value)
    const stakedOldLP = computed(() => rawStakedOldLP.value.toSignificant())
    const rawPendingIzaOldLP = computed(() => oldLPDistributor.rawOldPendingIza.value)
    const pendingIzaOldLP = computed(() => rawPendingIzaOldLP.value.toSignificant())
    const hasStakedOldLP = computed(() => rawStakedOldLP.value.greaterThan(0) || rawPendingIzaOldLP.value.greaterThan(0))

    const isDevMode = computed(() => process.env.NODE_ENV === "development")

    //const { formatted: token0FormattedBalance } = useTokenBalance(token0.value.address)
    //const { formatted: token1FormattedBalance } = useTokenBalance(token1.value.address)
    const {formatted: lpTokenFormattedBalance, raw: lpTokenBalance, fetchBalance: fetchLpTokenBalance } = useTokenBalance(pair.value.liquidityToken)

    const lpToggleLiquidity = ref(false)
    const lpToggleStake = ref(false)

    const {
      pairValueLocked,
      pairPrice,
    } = useLPInfo(pair.value)

    const priceProvidedLiquidity = computed(() => (pairPrice.value * parseFloat(lpTokenBalance.value.toSignificant())).toFixed(2))
    const priceStakedLiquidity = computed(() => (pairPrice.value * parseFloat(rawStakedLPBalance.value.toSignificant())).toFixed(2))

    const onToggleLiquidity = () => {
      lpToggleStake.value = false
      lpToggleLiquidity.value = !lpToggleLiquidity.value
    }

    const onToggleLpStake = () => {
      lpToggleLiquidity.value = false
      lpToggleStake.value = !lpToggleStake.value
    }

    const onClickOldLPWithdrawAndHarvest = async () => {
      try {
        await oldLPDistributor.oldWithdrawAndHarvest()
        await oldLPDistributor.fetchOldPendingIza()
        await oldLPDistributor.fetchOldStakedLPBalance()

        createToast('Success LP Stake', {
          type: 'success',
          position: 'bottom-center'
        })
      } catch (error) {

        createToast('Transaction Failed', {
          type: 'danger',
          position: 'bottom-center'
        })
      }
    }

    const resyncBalance = async () => {
      await fetchStakedLPBalance()
      await fetchLpTokenBalance()
    }

    onMounted(async () => {

      //if(props.address === '0xc1777FD7f07265A91Af7EF2A4dc7f7711D3660D8')
        await fetchPoolApr()
      //console.log('aa2')

    })

    return {
      pair,
      token0,
      token1,
      lpToggleLiquidity,
      onToggleLiquidity,
      lpTokenFormattedBalance,
      lpTokenBalance,
      lpToggleStake,
      onToggleLpStake,
      governanceAddress,
      formattedStakedLPBalance,
      rawStakedLPBalance,
      fetchStakedLPBalance,
      fetchLpTokenBalance,
      resyncBalance,
      poolAPR,
      isDevMode,
      priceProvidedLiquidity,
      priceStakedLiquidity,
      pairValueLocked,
      hasStakedOldLP,
      rawStakedOldLP,
      rawPendingIzaOldLP,
      stakedOldLP,
      pendingIzaOldLP,
      onClickOldLPWithdrawAndHarvest,
    }
  }
})

export default LiquidityPoolItem
