<template>
  <footer class="">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="d-sm-flex align-items-end justify-content-between">
            <div class="identity">
              <div class="footer-text">Innovate<br>Zest<br>Appreciation</div>
              <div class="footer-identity">MOO FINANCE</div>
            </div>
            <div class="communities">
              <a href="https://open.kakao.com/o/gpKWZtVd" target="_blank"><img src="../assets/community.kakao.svg" alt=""></a>
              <a href="https://t.me/moofinance" target="_blank"><img src="../assets/community.telegram.svg" alt=""></a>
              <a href="https://twitter.com/moo_finance" target="_blank"><img src="../assets/community.twitter.svg" alt=""></a>
              <a href="https://medium.com/@Moo_Finance" target="_blank"><img src="../assets/community.medium.svg" alt=""></a>
              <a href="https://docs.moo.loans/" target="_blank"><img src="../assets/community.gitbook.svg" alt=""></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="copyrights">Copyright ⓒ Moo Finance. All Rights Reserved.</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {defineComponent} from "vue";

const Footer = defineComponent({
  name: "Footer",
  setup(props) {

    return {}
  }
})

export default Footer
</script>

<style lang="scss" scoped>
footer {
  padding: 4rem 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 7.61%, rgba(0, 0, 0, 0.7) 50%), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  position: relative;
  z-index: 11;
  .identity {
    text-align: left;
    font-weight: 700;

    .footer-text {
      font-size: 1.5em;
      line-height: 2rem;
      margin-bottom: 2rem;
    }

    .footer-identity {
      font-size: 3em;
      line-height: 1;
      letter-spacing: -.02em;
    }
  }

  .communities {
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      img {
        width: 4rem;
        height: 4rem;
        border-radius: 2rem;
      }
    }
  }

  .copyrights {
    padding-top: 3rem;
    font-weight: 300;
    line-height: 1;
  }
}
</style>
