import {MooRouter, MooRouter__factory} from "@/contract-sdk/types";
import {getDeployment} from "@/sdk";
import {WETH9} from "@/sdk/constants";
import {Currency, CurrencyAmount, Fraction, Pair, Percent, Token, Trade} from "@/sdk/entities";
import {TradeType} from "@/sdk/enums";
import {validateAndParseAddress} from "@/sdk/functions";
import {RootState} from "@/store";
import Caver from "caver-js";
import {BigNumber, BigNumberish, ethers} from "ethers";
import invariant from "tiny-invariant";
import {computed, Ref, ref, watch} from "vue";
import {useStore} from "vuex";

declare let window: any

export function useTrade(_from: Ref<Token>, _to: Ref<Token>) {
  const store = useStore<RootState>()
  const pairs = computed<Array<Pair>>(() => store.getters["dex/pair/ALL_PAIR"])

  const rawAmountFrom = ref<BigNumberish>(0)
  const rawAmountTo = ref<BigNumberish>(0)

  const currencyAmountFrom = computed(()=>CurrencyAmount.fromRawAmount(_from.value, rawAmountFrom.value))
  const currencyAmountTo = computed(()=>CurrencyAmount.fromRawAmount(_to.value, rawAmountTo.value))

  const trade_mode = ref<TradeType>(TradeType.EXACT_OUTPUT)

  const trade = ref<Trade<Currency,Currency,TradeType> | null>()

  const rawAmount = computed(() => {
    if(!trade.value)
      return BigNumber.from(0)

    return trade_mode.value === TradeType.EXACT_INPUT ? trade.value.outputAmount.quotient : trade.value.inputAmount.quotient
  })
  const rawCurrency = computed(() => {
    if(!trade.value)
      return CurrencyAmount.fromRawAmount(trade_mode.value === TradeType.EXACT_INPUT ? _to.value : _from.value, 0)

    return trade_mode.value === TradeType.EXACT_INPUT ? trade.value.outputAmount : trade.value.inputAmount
  })

  const formattedCurrencyAmount = computed( () => {
    return rawCurrency.value.toSignificant(6)
  })

  const updateTrade = () => {
    if(trade_mode.value === TradeType.EXACT_INPUT) {
      trade.value = (Trade.bestTradeExactIn(pairs.value, currencyAmountFrom.value, currencyAmountTo.value.currency, {
        maxHops: 4,
        maxNumResults: 1
      })[0] ?? null)
    } else {
      trade.value = (Trade.bestTradeExactOut(pairs.value, currencyAmountFrom.value.currency, currencyAmountTo.value, {
        maxHops: 4,
        maxNumResults: 1
      })[0] ?? null)
    }
  }
  const realizedLPFeeRate = computed<Percent>(() => {
    if(!trade.value)
      return new Percent(0, 10000)

    const ONE_HUNDRED_PERCENT = new Percent(10000, 10000)
    const BASE_FEE = new Percent(30, 10000)
    const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

//    console.log('---123')

    return ONE_HUNDRED_PERCENT.subtract((
      trade.value.route.pairs.reduce<Fraction>(
        (currentFee: Fraction):Fraction => {
  //        console.log(`currentFee`,currentFee,currentFee.multiply(INPUT_FRACTION_AFTER_FEE))
          return currentFee.multiply(INPUT_FRACTION_AFTER_FEE)
        },
        ONE_HUNDRED_PERCENT
      )
    ))
  })


  const realizedLPFee = computed<CurrencyAmount<Currency>|null>(() => {
    if(realizedLPFeeRate.value.equalTo(0) || !trade.value)
      return null

    return CurrencyAmount.fromRawAmount(_from.value, realizedLPFeeRate.value.multiply(trade.value?.inputAmount).quotient)
  })

  const priceImpactWithoutFeeFraction = computed(() => {
    if(realizedLPFeeRate.value.equalTo(0) || !trade.value)
      return new Percent(0, 10000)
    return trade.value?.priceImpact.subtract(realizedLPFeeRate.value)
  })

  const allowedSlippage = ref(5)
  const slippageAdjustedAmounts = computed(() => {
    const pct = new Percent(Math.floor(allowedSlippage.value*100), 10000)
    return {
      from: trade.value ? trade.value?.maximumAmountIn(pct) : CurrencyAmount.fromRawAmount(_from.value, 0),
      to: trade.value ? trade.value?.minimumAmountOut(pct) : CurrencyAmount.fromRawAmount(_to.value, 0)
    }
  })

  const updateAmountFrom = (_amount:string) => {
    rawAmountFrom.value = ethers.utils.parseUnits(_amount ? _amount : '0', _from.value.decimals)

    trade_mode.value = TradeType.EXACT_INPUT

    updateTrade()
  }
  const updateAmountTo = (_amount:string) => {
    rawAmountTo.value = ethers.utils.parseUnits(_amount ? _amount : '0', _to.value.decimals)
    trade_mode.value = TradeType.EXACT_OUTPUT
    updateTrade()
  }
  const updateSlippage = (_slippage:number) => {
    allowedSlippage.value = _slippage
    updateTrade()
  }

  const address = computed(() => store.getters['web3/ADDRESS'])

  const doSwap = async () => {
    invariant(trade.value, 'NOT_SUPPORT_TRADE')

    const routerContract = (new ethers.Contract(getDeployment('MooRouter').address, MooRouter__factory.createInterface())) as MooRouter


    const etherIn = trade.value?.inputAmount.currency.equals(WETH9)
    const etherOut = trade.value?.outputAmount.currency.equals(WETH9)
    invariant(!(etherIn && etherOut), 'NATIVE_IN_OUT')

    const to: string = validateAndParseAddress(address.value)
    const path: string[] = trade.value.route.path.map(token => token.address)
    const amountIn: BigNumberish = slippageAdjustedAmounts.value.from.quotient
    const amountOut: BigNumberish = slippageAdjustedAmounts.value.to.quotient
    const deadline: BigNumberish = `0x${(Math.floor(new Date().getTime() / 1000) + 30).toString(16)}`


    //type methodInRouter = 'swapExactETHForTokens'|'swapExactTokensForETH'|'swapExactTokensForTokens'|'swapETHForExactTokens'|'swapTokensForExactETH'|'swapTokensForExactTokens'
    //let methodName: methodInRouter
    //let args: (string|string[]|BigNumberish)[]
    let value: BigNumberish
    let data: string

    if(trade.value?.tradeType == TradeType.EXACT_INPUT) {
      if( etherIn ) {
        value = amountIn
        data = routerContract.interface.encodeFunctionData('swapExactETHForTokens', [amountOut, path, to, deadline])
      } else if( etherOut) {
        value = BigNumber.from(0)
        data = routerContract.interface.encodeFunctionData('swapExactTokensForETH', [amountIn, amountOut, path, to, deadline])
      } else {
        value = BigNumber.from(0)
        data = routerContract.interface.encodeFunctionData('swapExactTokensForTokens', [amountIn, amountOut, path, to, deadline])
      }
    } else {
      if( etherIn ) {
        value = amountIn
        data = routerContract.interface.encodeFunctionData('swapETHForExactTokens', [amountOut, path, to, deadline])
      } else if( etherOut) {
        value = BigNumber.from(0)
        data = routerContract.interface.encodeFunctionData('swapTokensForExactETH',  [amountOut, amountIn, path, to, deadline])
      } else {
        value = BigNumber.from(0)
        data = routerContract.interface.encodeFunctionData('swapTokensForExactTokens',   [amountOut, amountIn, path, to, deadline])
      }
    }

    const caver = new Caver(window.klaytn)

    const gasLimit = await caver.rpc.klay.estimateGas({
      from: address.value,
      to: routerContract.address,
      data: data,
      value: value.toString()
    })

    const res = await caver.rpc.klay.sendTransaction({
      type: 'SMART_CONTRACT_EXECUTION',
      from: address.value,
      to: routerContract.address,
      data: data,
      gas: (Number(gasLimit) * 1.2).toFixed(0),
      value: value.toString()
    })
  }

  watch([_from, _to], () => {
    updateTrade()
  })

  return {
    rawAmountFrom,
    rawAmountTo,
    rawAmount,
    rawCurrency,
    formattedCurrencyAmount,
    doSwap,
    //amountFrom: computed(()=>ethers.utils.formatEther(rawAmountFrom.value)),
    //amountTo: computed(()=>ethers.utils.formatEther(rawAmountTo.value)),
    bestTrade:trade,
    priceImpact: computed(()=>trade.value?trade.value.priceImpact.toFixed():'0'),
    executionPrice: computed(()=>trade.value?trade.value.executionPrice.toSignificant():'0'),
    estimatedReceive: computed(()=>trade.value?ethers.utils.formatEther(trade_mode.value === TradeType.EXACT_INPUT ? trade.value.outputAmount.quotient : trade.value.inputAmount.quotient):'0'),
    updateAmountFrom,
    updateAmountTo,
    trade_mode,
    isExactInput: computed(() => trade_mode.value === TradeType.EXACT_INPUT),
    realizedLPFee,
    priceImpactWithoutFeeFraction,
    allowedSlippage,
    slippageAdjustedAmounts,
    updateSlippage,
  }
}
