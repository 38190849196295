import {getPairReserves} from "@/composables/useTokenBalance";
import {USDT, USDT_ADDRESS} from "@/sdk/constants";
import {
  Currency,
  CurrencyAmount,
  deserializePair,
  deserializeToken,
  Pair,
  Percent,
  Price,
  Token,
  Trade
} from "@/sdk/entities";
import {TradeType} from "@/sdk/enums";
import {RootState} from "@/store";
import pair from "@/store/dex/pair";
import axios from "axios";
import {BigNumber} from "ethers";
import {computed} from "vue";
import {useStore} from "vuex";


const API_ENDPOINT: string = process.env.VUE_APP_API_ENDPOINT!
const API = {
  GET_ALL_PAIR: API_ENDPOINT + '/pairs',
  GET_ALL_TOKEN: API_ENDPOINT + '/tokens',
}

export const getAllPairs = async (tokens: Array<Token>): Promise<Array<Pair>> => {
  //const { data } = await axios.get(API.GET_ALL_PAIR)

  const data: any = process.env.NODE_ENV === 'development' ?
    {"0x958b50014221a9F4d12893B80435021f212d2f7a":{"token0":"0x04E91A620616CB53aF80f634805C33865C4844EC","token1":"0x316676DF9DD4bDfbDCED5e16f70314E85565C80a","fee":["0x03e5","0x03e5"],"totalSupply":"0xab6d6b197541ae759e","reserve0":"0x02b5e3af16b1880000","reserve1":"0x2a5a058fc295ed000000","isKlayPool":false,"blockTimestampLast":1653617815},"0x148f6BF8fDa4Be0F845dc1C25B7087146B6E20C5":{"token0":"0x132152C2f30Fd9Ec948152338b515b49A79eA570","token1":"0x316676DF9DD4bDfbDCED5e16f70314E85565C80a","fee":["0x03e5","0x03e5"],"totalSupply":"0x0407ddeaf1d38e64","reserve0":"0x010c388d00","reserve1":"0x0f81ac3fb136c187e80000","isKlayPool":false,"blockTimestampLast":1653617819},"0x7E2A3CAB993b06b50C30980C9E0A089A1AdabB6d":{"token0":"0x316676DF9DD4bDfbDCED5e16f70314E85565C80a","token1":"0x76f7907413bC48955C3F68D7Ddc433F3588a2f59","fee":["0x03e5","0x03e5"],"totalSupply":"0x3d8109f2387a5783220e","reserve0":"0x0f81ac3fb136c187e80000","reserve1":"0xf3f20b8dfa69d00000","isKlayPool":false,"blockTimestampLast":1653617823}}
    : {"0x1BA60cf7272450E367f07625befcA94f98e921f6":{"token0":"0x12981F2827aEe18188E861493eA6540B4cE5404A","token1":"0x6Df40f423384048cddFaF97Bc562986D802b4431","fee":["0x03e5","0x03e5"],"totalSupply":"0x3ca82fcdcfb6dc15db64","reserve0":"0x0f162bf058c788d083e015","reserve1":"0xf3e06257d3f1f93904","isKlayPool":false,"blockTimestampLast":1650624066},"0x6bAD9121eE9dc8C29e22435ed2874d862ea5B124":{"token0":"0x12981F2827aEe18188E861493eA6540B4cE5404A","token1":"0xceE8FAF64bB97a73bb51E115Aa89C17FfA8dD167","fee":["0x03e5","0x03e5"],"totalSupply":"0x0158a685fb7c8175","reserve0":"0x052de5c0221d4d12abad6a","reserve1":"0x59966c20","isKlayPool":false,"blockTimestampLast":1650568919},"0x0754c4E661DBD4DFAED639450a9e26fA6C313c64":{"token0":"0x12981F2827aEe18188E861493eA6540B4cE5404A","token1":"0x5c74070FDeA071359b86082bd9f9b3dEaafbe32b","fee":["0x03e5","0x03e5"],"totalSupply":"0x1485235003f958e15975","reserve0":"0x052c6eaf74aae6c9ba8785","reserve1":"0x5163afdfad184557af","isKlayPool":false,"blockTimestampLast":1650568930}}

  for(let i =0;i<Object.keys(data).length;i++) {
    const pairAddress = Object.keys(data)[i]
    const { _reserve0, _reserve1 } = await getPairReserves(pairAddress)
    data[pairAddress]['reserve0'] = _reserve0
    data[pairAddress]['reserve1'] = _reserve1
  }

  return Object.keys(data).map((value,index,array): Pair => {
    return deserializePair(value, tokens, data[value])
  })
}

export const getAllTokens = async (): Promise<Array<Token>> => {
  //const { data } = await axios.get(API.GET_ALL_TOKEN)
  const data: any = process.env.NODE_ENV === 'development' ?
    {"0x04E91A620616CB53aF80f634805C33865C4844EC":{"name":"Wrapped Klay","symbol":"WKLAY","decimals":18,"logoURI":"","price":0,"oraclePrice":0},"0x316676DF9DD4bDfbDCED5e16f70314E85565C80a":{"name":"Test IZA","symbol":"tIZA","decimals":18,"logoURI":"","price":0,"oraclePrice":0},"0x132152C2f30Fd9Ec948152338b515b49A79eA570":{"name":"Test USDT","symbol":"tUSDT","decimals":6,"logoURI":"","price":0,"oraclePrice":0},"0x76f7907413bC48955C3F68D7Ddc433F3588a2f59":{"name":"Test KDAI","symbol":"tKDAI","decimals":18,"logoURI":"","price":0,"oraclePrice":0}}
    : {"0x12981F2827aEe18188E861493eA6540B4cE5404A":{"name":"Moo Finance Protocol","symbol":"IZA","decimals":18,"logoURI":"","price":0,"oraclePrice":0},"0x6Df40f423384048cddFaF97Bc562986D802b4431":{"name":"Wrapped Klay","symbol":"WKLAY","decimals":18,"logoURI":"","price":0,"oraclePrice":0},"0xceE8FAF64bB97a73bb51E115Aa89C17FfA8dD167":{"name":"Orbit Bridge Klaytn USD Tether","symbol":"KUSDT","decimals":6,"logoURI":"","price":0,"oraclePrice":0},"0x5c74070FDeA071359b86082bd9f9b3dEaafbe32b":{"name":"Klaytn Dai","symbol":"KDAI","decimals":18,"logoURI":"","price":0,"oraclePrice":0}}

  return Object.keys(data).map(((value, index, array): Token => {
    return deserializeToken(value, data[value])
  }))
}

export const checkApproval = async () => {
  //
}

const MAX_HOPS = 3
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(1000, 10000)
export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export function useV2TradeExactOut(
  currencyIn?: Currency,
  currencyAmountOut?: CurrencyAmount<Currency>,
  { maxHops = MAX_HOPS } = {}
): Trade<Currency, Currency, TradeType.EXACT_OUTPUT> | undefined {

  //console.log('useV2TradeExactOut', currencyIn?.symbol,' => ', currencyAmountOut?.currency.symbol)
  const store = useStore<RootState>()
  //console.log(store)
  const pairs = computed(() => store.getters['dex/pair/ALL_PAIR'])

  if (currencyIn && currencyAmountOut && pairs.value.length > 0) {
    if (maxHops === 1) {
      return (
        Trade.bestTradeExactOut(pairs.value, currencyIn, currencyAmountOut, {
          maxHops: 1,
          maxNumResults: 1,
        })[0]
      )
    }
    // search through trades with varying hops, find best trade out of them
    let bestTradeSoFar: Trade<Currency, Currency, TradeType.EXACT_OUTPUT> | undefined = undefined
    for (let i = 1; i <= maxHops; i++) {
      const currentTrade =
        Trade.bestTradeExactOut(pairs.value, currencyIn, currencyAmountOut, {
          maxHops: i,
          maxNumResults: 1,
        })[0]
      //console.log('currentTrade', currentTrade)
      if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
        bestTradeSoFar = currentTrade
      }
    }
    return bestTradeSoFar
  }
}

export const useSimpleTrade = () => {
  const store = useStore<RootState>()
  //console.log(store)
  const pairs = computed(() => store.getters['dex/pair/ALL_PAIR'])

  const v2Trade = (
    currencyIn?: Currency,
    currencyAmountOut?: CurrencyAmount<Currency>,
    { maxHops = MAX_HOPS } = {}
  ): Trade<Currency, Currency, TradeType.EXACT_OUTPUT> | undefined => {

    //console.log('useV2TradeExactOut', currencyIn?.symbol,' => ', currencyAmountOut?.currency.symbol)

    if (currencyIn && currencyAmountOut && pairs.value.length > 0) {
      if (maxHops === 1) {
        return (
          Trade.bestTradeExactOut(pairs.value, currencyIn, currencyAmountOut, {
            maxHops: 1,
            maxNumResults: 1,
          })[0]
        )
      }
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade<Currency, Currency, TradeType.EXACT_OUTPUT> | undefined = undefined
      for (let i = 1; i <= maxHops; i++) {
        const currentTrade =
          Trade.bestTradeExactOut(pairs.value, currencyIn, currencyAmountOut, {
            maxHops: i,
            maxNumResults: 1,
          })[0]
        //console.log('currentTrade', currentTrade)
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }
  }

  return {
    v2Trade
  }
}

export function useUSDTPrice(currency: Currency): Price<Currency, Token> | undefined {
  //console.log(process.env.VUE_APP_NETWORK_ID, USDT_ADDRESS[parseInt(process.env.VUE_APP_NETWORK_ID!)])

  /*const amountOut = process.env.NODE_ENV === 'development' ?
    CurrencyAmount.fromRawAmount(
    new Token(USDT_ADDRESS[parseInt(process.env.VUE_APP_NETWORK_ID!)],6, 'tUSDT', 'Test USDT' ),
    100) :
    CurrencyAmount.fromRawAmount(
      new Token(USDT_ADDRESS[parseInt(process.env.VUE_APP_NETWORK_ID!)],6, 'KUSDT', 'Orbit Bridge Klaytn USD Tether' ),
      100)*/

  const amountOut = CurrencyAmount.fromRawAmount(USDT, 100)

  const stablecoin = amountOut.currency

  const v2USDTTrade = useV2TradeExactOut(currency, amountOut, {
    maxHops: MAX_HOPS,
  })

  //console.log( 'v2USDTTrade', v2USDTTrade)
  if(currency.name === stablecoin.name) {
//    console.log('is stable')
    return new Price(stablecoin, stablecoin, '1', '1')
  }

  if (v2USDTTrade) {
    //const path = v2USDTTrade.route.path.map(v => v.symbol).join(' => ')
 //   console.log(path)

    //console.log(currency.symbol, ' ===> ', stablecoin.symbol)

    const { numerator, denominator } = v2USDTTrade.route.midPrice
    return new Price(currency, stablecoin, denominator, numerator)
  }
}

export function isTradeBetter(
  tradeA: Trade<Currency, Currency, TradeType> | undefined | null,
  tradeB: Trade<Currency, Currency, TradeType> | undefined | null,
  minimumDelta: Percent = ZERO_PERCENT
): boolean | undefined {
  if (tradeA && !tradeB) return false
  if (tradeB && !tradeA) return true
  if (!tradeA || !tradeB) return undefined

  if (
    tradeA.tradeType !== tradeB.tradeType ||
    !tradeA.inputAmount.currency.equals(tradeB.inputAmount.currency) ||
    !tradeB.outputAmount.currency.equals(tradeB.outputAmount.currency)
  ) {
    throw new Error('Comparing incomparable trades')
  }

  if (minimumDelta.equalTo(ZERO_PERCENT)) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice)
  } else {
    return tradeA.executionPrice.asFraction
      .multiply(minimumDelta.add(ONE_HUNDRED_PERCENT))
      .lessThan(tradeB.executionPrice)
  }
}
