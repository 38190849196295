import { ChainId } from '@/sdk/enums'
import {AddressMap} from "@/sdk/types"


export const SUSHI_ADDRESS: AddressMap = {
  [ChainId.BAOBAB]: '0x316676DF9DD4bDfbDCED5e16f70314E85565C80a',
  [ChainId.CYPRESS]: '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
}

export const USDT_ADDRESS: AddressMap = {
  [ChainId.BAOBAB]: '0x132152C2f30Fd9Ec948152338b515b49A79eA570',
  [ChainId.CYPRESS]: '0xceE8FAF64bB97a73bb51E115Aa89C17FfA8dD167',
}

export const USD_ADDRESS: AddressMap = {
  [ChainId.BAOBAB]: USDT_ADDRESS[ChainId.BAOBAB],
  [ChainId.CYPRESS]: USDT_ADDRESS[ChainId.CYPRESS],
}

export const WETH9_ADDRESS: AddressMap = {
  [ChainId.BAOBAB]: '0x04E91A620616CB53aF80f634805C33865C4844EC',
  [ChainId.CYPRESS]: '0x6Df40f423384048cddFaF97Bc562986D802b4431',
}

export const WNATIVE_ADDRESS: AddressMap = {
  [ChainId.BAOBAB]: WETH9_ADDRESS[ChainId.BAOBAB],
  [ChainId.CYPRESS]: WETH9_ADDRESS[ChainId.CYPRESS],
}
