
import {

  useApprovalTokenToRouter, useKLast,
  useSwapRouter,
  useTokenBalance, useTokenBalanceByAddress,
  useTotalSupply
} from "@/composables/useTokenBalance";
import {WETH9} from "@/sdk/constants";
import {CurrencyAmount, Pair, Token} from "@/sdk/entities";
import {RootState} from "@/store";
import {BigNumber, ethers} from "ethers";
import {createToast} from "mosha-vue-toastify";
import {computed, defineComponent, ref, watch} from "vue";
import {useStore} from "vuex";

const LiquidityProvide = defineComponent({
  name: "LiquidityProvide",
  props: {
    address: {required: true},
  },
  emits: ['updateProvide'],
  setup(props, { emit }) {
    const store = useStore<RootState>()
    const pair = computed<Pair>(() => store.getters["dex/pair/GET_PAIR"](props.address))
    const token0 = computed<Token>(() => pair.value.token0)
    const token1 = computed<Token>(() => pair.value.token1)

    const depositMode = ref<boolean>(true)
    const changeDepositMode = (deposit:boolean) => depositMode.value = deposit

    const { formatted: token0FormattedBalance, raw: token0RawBalance } = useTokenBalance(token0.value)
    const { formatted: token1FormattedBalance, raw: token1RawBalance } = useTokenBalance(token1.value)
    const { formatted: lpTokenFormattedBalance, raw: lpTokenRawBalance, fetchBalance: fetchLpTokenBalance } = useTokenBalance(pair.value.liquidityToken)
    const { raw: lpTokenTotalSupply, fetchSupply: fetchLpTokenSupply } = useTotalSupply(pair.value.liquidityToken)
    const { raw: kLast, fetchKLast: fetchLpKLast } = useKLast(pair.value.liquidityToken)
    const { raw: token0BalanceInLP, fetchBalance: fetchToken0BalanceInLp } = useTokenBalanceByAddress(token0.value, pair.value.liquidityToken.address)
    const { raw: token1BalanceInLP, fetchBalance: fetchToken1BalanceInLp } = useTokenBalanceByAddress(token1.value, pair.value.liquidityToken.address)

    const amountToken0 = ref('')
    const amountToken1 = ref('')
    const rawAmountToken0 = computed(() => CurrencyAmount.fromRawAmount(token0.value, ethers.utils.parseUnits(Number(amountToken0.value).toFixed(token0.value.decimals), token0.value.decimals)))
    const rawAmountToken1 = computed(() => CurrencyAmount.fromRawAmount(token1.value, ethers.utils.parseUnits(Number(amountToken1.value).toFixed(token1.value.decimals), token1.value.decimals)))

    const amountLP = ref('')
    const rawAmountLP = computed(() => CurrencyAmount.fromRawAmount(pair.value.liquidityToken, ethers.utils.parseUnits(Number(amountLP.value).toFixed(pair.value.liquidityToken.decimals), pair.value.liquidityToken.decimals)))

    const withdrawEstimateToken0 = computed(() => rawWithdrawEstimateToken0.value.toSignificant(6))
    const withdrawEstimateToken1 = computed(() => rawWithdrawEstimateToken1.value.toSignificant(6))

    const rawWithdrawEstimateToken0 = computed(() => {
      if(!amountLP.value)
        return CurrencyAmount.fromRawAmount(rawAmountLP.value.currency, 0)


      if(rawAmountLP.value.greaterThan(lpTokenRawBalance.value))
        return CurrencyAmount.fromRawAmount(rawAmountLP.value.currency, 0)

      return CurrencyAmount.fromRawAmount(token0.value,rawAmountLP.value.quotient.mul(token0BalanceInLP.value.quotient).div(lpTokenTotalSupply.value.quotient))
    })
    const rawWithdrawEstimateToken1 = computed(() => {
      if(!amountLP.value)
        return CurrencyAmount.fromRawAmount(rawAmountLP.value.currency, 0)


      if(rawAmountLP.value.greaterThan(lpTokenRawBalance.value))
        return CurrencyAmount.fromRawAmount(rawAmountLP.value.currency, 0)

      //return rawAmountLP.value.multiply(token1BalanceInLP.value).divide(lpTokenTotalSupply.value)
      return CurrencyAmount.fromRawAmount(token1.value,rawAmountLP.value.quotient.mul(token1BalanceInLP.value.quotient).div(lpTokenTotalSupply.value.quotient))
    })

    const { raw: token0Approved, approve: token0Approve, fetchApproved: fetchToken0Approved } = useApprovalTokenToRouter(token0.value)
    const { raw: token1Approved, approve: token1Approve, fetchApproved: fetchToken1Approved } = useApprovalTokenToRouter(token1.value)
    const { raw: lpTokenApproved, approve: lpTokenApprove, fetchApproved: fetchLpTokenApproved } = useApprovalTokenToRouter(pair.value.liquidityToken)

    const { addLiquidity: _addLiquidity, removeLiquidity: _removeLiquidity } = useSwapRouter()

    const addLiquidityBtnDisabled = computed(() => {
      if(!amountToken0.value && !amountToken1.value)
        return true

      if(amountToken1.value && rawAmountToken1.value.greaterThan(token1RawBalance.value))
        return true

      if(amountToken0.value && rawAmountToken0.value.greaterThan(token0RawBalance.value))
        return true

      return false
    })

    const removeLiquidityBtnDisabled = computed(() => {
      if(!amountLP.value)
        return true;

      if(amountLP.value && rawAmountLP.value.greaterThan(lpTokenRawBalance.value))
        return true

      return false;
    })
    const addLiquidityActionText = computed(() => {
      if(!amountToken0.value && !amountToken1.value)
        return `유동성 추가`

      if(amountToken1.value && rawAmountToken1.value.greaterThan(token1RawBalance.value))
        return `${token1.value.symbol} 토큰 잔고가 부족합니다.`

      if(amountToken0.value && rawAmountToken0.value.greaterThan(token0RawBalance.value))
        return `${token0.value.symbol} 토큰 잔고가 부족합니다.`

      if(amountToken1.value && token1.value.address != WETH9.address && token1Approved.value.lessThan(rawAmountToken1.value))
        return `${token1.value.symbol} 전송 승인`

      if(amountToken0.value && token0.value.address != WETH9.address && token0Approved.value.lessThan(rawAmountToken0.value))
        return `${token0.value.symbol} 전송 승인`

      return '유동성 추가'
    })

    const removeLiquidityActionText = computed( () => {
      if(!amountLP.value)
        return `유동성 출금`

      if(amountLP.value && rawAmountLP.value.greaterThan(lpTokenRawBalance.value))
        return `LP 토큰 잔고가 부족합니다.`

      if(amountLP.value && lpTokenApproved.value.lessThan(rawAmountLP.value))
        return `LP 전송 승인`

      return '유동성 출금'
    })

    const onInput1 = () => {
      if(!amountToken1.value) {
        amountToken0.value = ''
        return
      }

      amountToken0.value = pair.value.priceOf(token1.value).quote(rawAmountToken1.value).toSignificant(6)

      //checkBalance()
    }

    const onKeyupDecimal = (e:KeyboardEvent) => {
      const charCode = e.which ? e.which : e.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        e.preventDefault()
        return false
      } else {
        return true
      }
    }

    const onInput0 = () => {
      if(!amountToken0.value) {
        amountToken1.value = ''
        return
      }

      amountToken1.value = pair.value.priceOf(token0.value).quote(rawAmountToken0.value).toSignificant(6)

      //checkBalance()
    }

    const onInputLP = () => {
      //
    }

    const checkBalance = () => token0RawBalance.value.greaterThan(rawAmountToken0.value) && token1RawBalance.value.greaterThan(rawAmountToken1.value) // gt >

    const checkApprovalToken0 = () => {
      return token0.value.address != WETH9.address && token0Approved.value.lessThan(rawAmountToken0.value)

      //let method = 'addLiquidity'
      //if(token0.value.address == WETH9.address || token1.value.address == WETH9.address) {
      //  method = 'addLiquidityEth'
      //}
      //if(token)
    }

    const checkApprovalToken1 = () => {
      return token1.value.address != WETH9.address && token1Approved.value.lessThan(rawAmountToken1.value)
    }

    const checkApprovalLpToken = () => {
      return lpTokenApproved.value.lessThan(rawAmountLP.value)
    }

    const removeLiquidity = async () => {
      try {
      if(checkApprovalLpToken()) {
        await lpTokenApprove()
        await fetchLpTokenApproved()
        createToast('Success Approve', {
          type: 'success',
          position: 'bottom-center'
        })
        return
      }

      await _removeLiquidity(pair.value, rawAmountLP.value.quotient, rawWithdrawEstimateToken0.value.quotient, rawWithdrawEstimateToken1.value.quotient)
      await fetchLpTokenBalance()

      amountLP.value = ''
      createToast('Success Withdraw', {
        type: 'success',
        position: 'bottom-center'
      })
        emit('updateProvide')
      } catch (error) {

        createToast('Transaction Failed', {
          type: 'danger',
          position: 'bottom-center'
        })
      }
    }

    const addLiquidity = async () => {
      try {
        if (checkApprovalToken0()) {
          await token0Approve()
          await fetchToken0Approved()

          createToast('Success Approve', {
            type: 'success',
            position: 'bottom-center'
          })
          return
        }


        if (checkApprovalToken1()) {
          await token1Approve()
          await fetchToken1Approved()

          createToast('Success Approve', {
            type: 'success',
            position: 'bottom-center'
          })
          return
        }


        await _addLiquidity(pair.value, rawAmountToken0.value.quotient, rawAmountToken1.value.quotient)
        await fetchLpTokenBalance()
        amountToken0.value = ''
        amountToken1.value = ''


        createToast('Success Deposit', {
          type: 'success',
          position: 'bottom-center'
        })
        emit('updateProvide')

      } catch (error) {

        createToast('Transaction Failed', {
          type: 'danger',
          position: 'bottom-center'
        })
      }
    }

    const onClickDepositToken0Max = () => {
      if(!Number(token0RawBalance.value.toSignificant()))
        return

      amountToken0.value = token0FormattedBalance.value
      amountToken1.value = pair.value.priceOf(token0.value).quote(rawAmountToken0.value).toSignificant(6)
    }

    const onClickDepositToken1Max = () => {
      if(!Number(token1RawBalance.value.toSignificant()))
        return

      amountToken1.value = token1FormattedBalance.value
      amountToken0.value = pair.value.priceOf(token1.value).quote(rawAmountToken1.value).toSignificant(6)
    }

    const onClickWithdrawMax = () => {
      if(!Number(lpTokenRawBalance.value.toSignificant()))
        return

      amountLP.value = lpTokenFormattedBalance.value
    }

    return {
      pair,
      token0,
      token1,
      token0FormattedBalance,
      token1FormattedBalance,
      lpTokenFormattedBalance,
      amountToken0,
      amountToken1,
      onInput1,
      onInput0,
      onKeyupDecimal,
      amountLP,
      onInputLP,
      addLiquidityActionText,
      removeLiquidityActionText,
      addLiquidity,
      depositMode,
      changeDepositMode,
      addLiquidityBtnDisabled,
      removeLiquidityBtnDisabled,
      withdrawEstimateToken0,
      withdrawEstimateToken1,
      removeLiquidity,

      onClickWithdrawMax,
      onClickDepositToken0Max,
      onClickDepositToken1Max,
    }
  }
})

export default LiquidityProvide
