/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  MooOracleLibrary,
  MooOracleLibraryInterface,
} from "../MooOracleLibrary";

const _abi = [
  {
    inputs: [],
    name: "Q112",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "RESOLUTION",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x60c4610025600b82828239805160001a60731461001857fe5b30600052607381538281f3fe7300000000000000000000000000000000000000003014608060405260043610603d5760003560e01c80633bf7a83e146042578063552f888a14605a575b600080fd5b60486076565b60408051918252519081900360200190f35b60606089565b6040805160ff9092168252519081900360200190f35b6e01000000000000000000000000000081565b60708156fea26469706673582212209b775f9528bf1a84a20b048d9ba38c12f089c5903acd1b56b71509216fccfd9864736f6c63430007060033";

export class MooOracleLibrary__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<MooOracleLibrary> {
    return super.deploy(overrides || {}) as Promise<MooOracleLibrary>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): MooOracleLibrary {
    return super.attach(address) as MooOracleLibrary;
  }
  connect(signer: Signer): MooOracleLibrary__factory {
    return super.connect(signer) as MooOracleLibrary__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): MooOracleLibraryInterface {
    return new utils.Interface(_abi) as MooOracleLibraryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MooOracleLibrary {
    return new Contract(address, _abi, signerOrProvider) as MooOracleLibrary;
  }
}
