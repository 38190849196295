import { createApp } from "vue";
import App from "./App.vue";
import Header from "./components/Header.vue"
import Footer from "./components/Footer.vue"
import router from "./router";
import store from "./store";
import 'mosha-vue-toastify/dist/style.css'
import {createI18n} from "vue-i18n";
import languages from "@/i18n";
import { VueClipboard } from '@soerenmartius/vue3-clipboard';
import VueGtag from "vue-gtag"
import VueLazyLoad from 'vue3-lazyload'
import veProgress from 'vue-ellipse-progress'


const i18n = createI18n({
  legacy: false,
  locale: 'ko',
  fallbackLocale: 'ko',
  messages: languages,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  missingWarn: false,
  warnHtmlMessage: false,
})



const app = createApp(App)
app.use(store)
app.use(router).use(i18n).use(VueClipboard)
app.use(VueGtag, {
  appName: 'Moo Finance Web',
  pageTrackerScreenviewEnabled: true,
  config: { id: "G-ZXBW2TJD3G" }
},router)
app.use(VueLazyLoad, {
  log: false,
})
app.use(veProgress)

app.component('app-header', Header)
app.component('app-footer', Footer)
app.mount("#app")

