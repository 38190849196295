import {RootState} from "@/store";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";

export interface MultipleToStakePeriod {
  [multiple: number]: number
}

export class StakeState {
  public periods:MultipleToStakePeriod = {
    100 : 120,
    175 : 240,
    225 : 360
  }


}


const mutations:MutationTree<StakeState> = {

}

const actions: ActionTree<StakeState, RootState> = {

}

const getters: GetterTree<StakeState, RootState> = {
  Periods(state): MultipleToStakePeriod {
    return state.periods
  }
}

const moduleStake: Module<StakeState, RootState> = {
  namespaced: true,
  state: new StakeState(),
  actions,
  mutations,
  getters,
}

export default moduleStake
