
import {Token} from "@/sdk/entities";
import {RootState} from "@/store";
import {computed, defineComponent, nextTick, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import TokenSwap from "@/components/TokenSwap.vue";
import TokenChart from "@/components/TokenChart.vue";

const Swap = defineComponent({
  name: "Swap",
  components: {TokenSwap, TokenChart},

  setup(props) {
    const store = useStore<RootState>()
    const tokens = computed<Array<Token>>(() => store.getters['dex/token/TOKENS'])
    const fromAddress = ref<string>('')
    const toAddress = ref<string>('')
    const onLoad = ref(false)

    const fromToken = computed(() => store.getters['dex/token/TokenByAddress'](fromAddress.value))
    const toToken = computed(() => store.getters['dex/token/TokenByAddress'](toAddress.value))
    const symbol = computed(() => {
      return `${fromToken.value?fromToken.value.symbol:'tIZA'}/${toToken.value?toToken.value.symbol:'KLAY'}`
    })

    onMounted(async () => {
      await store.dispatch('dex/token/loadTokens')
      await store.dispatch('dex/pair/loadPairs')

      fromAddress.value = tokens.value[1].address
      toAddress.value = tokens.value[0].address

      //console.log(fromAddress.value, toAddress.value)

      onLoad.value = true
    })
    return {
      fromAddress,
      toAddress,
      tokens,
      onLoad,
      symbol,
    }
  }
})

export default Swap
