import {getTokenBalance} from "@/composables/useTokenBalance";
import {getAllTokens} from "@/sdk/api/dex";
import {CurrencyAmount, Token} from "@/sdk/entities";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store";

export class TokenState {
  public tokens = new Array<Token>()

  public tokenBalance: {
    [tokenAddress: string]: CurrencyAmount<Token>
  } = {}
}

const mutations:MutationTree<TokenState> = {
  SET_TOKENS(state, payload:Array<Token>) {
    state.tokens = payload
  },
  SET_TOKEN_BALANCE(state, payload:CurrencyAmount<Token>) {
    state.tokenBalance[payload.currency.address] = payload
  }
}

const actions:ActionTree<TokenState, RootState> = {
  setContract(context) {
    //
  },
  async loadTokens({ commit }) {
    const tokens = await getAllTokens()

    commit('SET_TOKENS', tokens)
  },
  async fetchTokenBalances( { commit, state, rootState }) {
    //if(!rootState.web3.selectedAddress)
    for(let i = 0; i < state.tokens.length; i++) {
      commit('SET_TOKEN_BALANCE', await getTokenBalance(state.tokens[i], rootState.web3.selectedAddress))
    }
  }

}

const getters: GetterTree<TokenState, RootState> = {

  IS_OWNER(state, getters, rootState, rootGetters) {
    return true
  },
  TOKENS(state): Array<Token> {
    return state.tokens
  },
  TokenByAddress(state) {
    return (address:string):Token|undefined => {
      return state.tokens.find(v=>v.address === address)
    }
  },
  TokenBalances(state) {
    return state.tokenBalance
  }
}


const moduleToken: Module<TokenState, RootState> = {
  namespaced: true,
  state: new TokenState(),
  actions,
  mutations,
  getters
}

export default moduleToken
