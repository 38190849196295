
import {useStakeHistory} from "@/composables/useStake";
import {RootState} from "@/store";
import {MultipleToStakePeriod} from "@/store/dex/stake";
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";

const StakeHistory = defineComponent({
  name: "StakeHistory",
  props: {
    govTokenAmount: {
      type: String,
      require: true,
      default: '0'
    }
  },
  setup(props) {
    const onLoad = ref(false)
    const store = useStore<RootState>()

    const {
      periods,
      summary,
      histories,
      fetchStakeHistory,
    } = useStakeHistory()

    const outputHistories = ref(histories.value.reverse())
    onMounted(async () => {
      onLoad.value = true
    })

    watch(histories, () => {
      outputHistories.value = histories.value.reverse()
    })
    watch(()=>props.govTokenAmount, async () => {
      await fetchStakeHistory()
    }, {
      immediate: false
    })

    return {
      onLoad,
      summary,
      periods,
      outputHistories,
    }
  }
})

export default StakeHistory
