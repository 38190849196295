
import {Token} from "@/sdk/entities";
import {RootState} from "@/store";
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";

const SelectedToken = defineComponent({
  name: "SelectedToken",
  props: {
    tokenAddress: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const store = useStore<RootState>()
    const token = computed(() => store.getters['dex/token/TokenByAddress'](props.tokenAddress))

    return {
      token
    }
  }
})

export default SelectedToken
