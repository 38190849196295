
import {useGenesisPass} from "@/composables/useTokenBalance";
import {computed, defineComponent, nextTick, onMounted, ref, watch} from "vue";
import Wallet from "@/components/Wallet.vue";
import {useStore} from "vuex";
import {RootState} from "@/store";
import {createToast} from "mosha-vue-toastify";
import {Locale, useI18n} from "vue-i18n";

const Header = defineComponent({
  name: "Header",
  components: {Wallet},
  setup(props) {
    const store = useStore<RootState>()
    const networkName = computed<string>(() => process.env.VUE_APP_NETWORK_NAME!)
    const currentBlockNumber = ref<string>('0')
    const walletConnected = computed(() => store.getters['wallet/connected'])

    const isProdMode = computed(()=>process.env.NODE_ENV==='production')

    const {t, locale, availableLocales} = useI18n({useScope: 'global'})
    //const {isHolder} = useGenesisPass()

    const changeLocale = (selectedLocale: Locale) => {
      locale.value = selectedLocale

      if(locale.value !== localStorage.getItem('locale'))
        localStorage.setItem('locale', selectedLocale)
    }
    onMounted(async () => {
      await store.dispatch('web3/getBlockNumber')

      currentBlockNumber.value = store.getters['web3/BLOCK_NUMBER']
      window.setInterval(() => {
        currentBlockNumber.value = (parseInt(currentBlockNumber.value)+1).toString()
      }, 1000)
      window.setInterval(async () => {
        await store.dispatch('web3/getBlockNumber')
      }, 10000)

      const storedLocale = localStorage.getItem('locale')
      if(storedLocale)
        locale.value = storedLocale

    })

    watch(walletConnected, (newAccount, oldAccount) => {
      //
      //console.log('walletConnected changed', newAccount, oldAccount)
    })

    const onClickNotSupported = () => {
      createToast('Coming SOON', {
        position: 'bottom-center'
      })
    }

    return {
   //   isHolder,
      networkName,
      currentBlockNumber,
      t, locale, availableLocales,
      changeLocale,
      onClickNotSupported,
    }
  }
})

export default Header
