
import LiquidityPoolList from "@/components/LiquidityPoolList.vue";
import useWeb3Provider from "@/composables/useWeb3Provider";
import {RootState} from "@/store";
import {computed, defineComponent, onMounted} from "vue";
import {useStore} from "vuex";

const Pool = defineComponent({
  name: "Pool",
  components: {LiquidityPoolList},

  setup(props) {
    const store = useStore<RootState>()
    const addresses = computed(() => store.getters['dex/pair/PAIR_ADDRESSES'])

    onMounted(async () => {
      await store.dispatch('dex/token/loadTokens')
      await store.dispatch('dex/pair/loadPairs')

    })
    return {
      addresses,
    }
  }
})

export default Pool
