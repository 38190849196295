import {getDeployment} from "@/sdk";

export * from './chain'
export * from './addresses'
export * from './tokens'
export * from './numbers'


export const FACTORY_ADDRESS = getDeployment('MooFactory').address
export const INIT_CODE_HASH =
  process.env.NODE_ENV === 'development'
    ? '0xdf6b54b26a8ff238275665aff8a0a7a41faaf9b1d10dde34c19bdc2adb3710af'
    : '0x422529c4ef82d8437baae2a8f30f8774dccec1bbd2b2873654df029e8bd04db1'


export const POOL_IDS: { [address: string]: number } = process.env.NODE_ENV === 'development' ? {
  '0x958b50014221a9F4d12893B80435021f212d2f7a': 0,
  '0x148f6BF8fDa4Be0F845dc1C25B7087146B6E20C5': 1,
  '0x7E2A3CAB993b06b50C30980C9E0A089A1AdabB6d': 2,
} :   {
  '0x1BA60cf7272450E367f07625befcA94f98e921f6': 0,
  '0x6bAD9121eE9dc8C29e22435ed2874d862ea5B124': 1,
  '0x0754c4E661DBD4DFAED639450a9e26fA6C313c64': 2,
}
