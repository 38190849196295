import Pool from "@/views/Pool.vue";
import Stake from "@/views/Stake.vue";
import Swap from "@/views/Swap.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Home from "@/views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Pool,
  },
  {
    path: "/swap",
    name: "Swap",
    component: Swap,
  },
  {
    path: "/pool",
    name: "Pool",
    component: Pool
  },
  {
    path: "/stake",
    name: 'Stake',
    component: Stake,
  }
  //{
  //  path: "/about",
  //  name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
//    component: () =>
//      import(/* webpackChunkName: "about" */ "../views/About.vue"),
//  },
];
const router = createRouter({
  history: createWebHistory(),//process.env.BASE_URL),
  routes,
});

export default router;
